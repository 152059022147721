import { useTranslation } from "react-i18next";
import { useRef, useContext } from "react";
import { StyledAboutUsSectionContainer, StyledMainPhrase, StyledAboutUsLink, StyledImageContainer, TextContainer } from "./AboutUsSection.styles";
import { MainContext } from "../../Context";
import useOnScreen from "../../Hooks/useOnScreen";

const AboutUsSection = () => {

    const {
        language
    } = useContext(MainContext);

    const titleRef = useRef();
    const imageRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);
    const imageIsVisible = useOnScreen(imageRef);

    const { t } = useTranslation();

    return (
        <StyledAboutUsSectionContainer>
            <TextContainer ref={titleRef} visibility={titleIsVisible ? 1 : 0}>
                <StyledMainPhrase>{t('HOMEPAGE').ABOUT_US_SECTION_MAIN_PHRASE}<br/><span>{t('HOMEPAGE').ABOUT_US_SECTION_MAIN_PHRASE_ITALIC}</span></StyledMainPhrase>
                <StyledAboutUsLink to={'/' + language + '/about-us'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').ABOUT_US}</StyledAboutUsLink>
            </TextContainer>
            <StyledImageContainer ref={imageRef} visibility={imageIsVisible ? 1 : 0}>
                <img src="/assets/images/home/about_us_section.webp" alt="A balcon with view to the beach"/>
            </StyledImageContainer>
        </StyledAboutUsSectionContainer>
    );
}

export { AboutUsSection };