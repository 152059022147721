import { Fragment, useContext } from 'react';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { MainContext } from "../Context";
import { WhatsAppButton } from '../WhatsAppButton';
import { useTranslation } from "react-i18next";

const Layout = (props) => {

    const {
        section
    } = useContext(MainContext);

    document.title = section + ' | Cape';

    const { t } = useTranslation();

    return (
        <Fragment>
            <Header />
            {props.children}
            <WhatsAppButton text={t('WHATSAPP_TEXT')}/>
            <Footer/>
        </Fragment>
    );
}

export { Layout };