import { Fragment, useState } from "react";
import { Hero } from './Hero';
import { ServiceSection } from "./ServiceSection";
import { useContext, useEffect } from "react";
import { MainContext } from "../Context";
import { useTranslation } from "react-i18next";
import { PackagesSection } from "./PackagesSection";
import { CarteMenuSection } from "./CarteMenuSection";
import { CarteMenuNotificationBar, CarteMenuCounter, CarteMenuCart, CartButton, CloseCartButton } from "./Services.styles";
import { isDisabled } from "@testing-library/user-event/dist/utils";


const Services = () => {

    const {
        onChangeSection,
        language,
        changeContactForm
    } = useContext(MainContext);

    const { t } = useTranslation();

    const onSubmitCart = () => {
        window.scrollTo(0,0);
        let message = t('SERVICES_PAGE').SERVICES_PACKAGES.MESSAGE_TEXT_BEGIN + '\n';
        if(selectedPackage !== -1){
            message = message + '\n\t' + servicePackages.PACKAGE + ' ' + servicePackages.PACKAGES[selectedPackage].TITLE;
        }
        selectedSubservices.filter((obj) => obj.selected).forEach(subservice => {
            message = message + '\n\t' + services[subservice.service].SUBSERVICES[subservice.subservice].TITLE;
        });
        message = message + '\n\n' + t('SERVICES_PAGE').SERVICES_PACKAGES.MESSAGE_TEXT_END;
        changeContactForm(message);
    }

    useEffect(() => {
        onChangeSection(t('PAGES_NAME').SERVICES);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const [carteMenu, setCarteMenu] = useState(false);
    const [showCart, setShowCart] = useState(false);
    /* const [notificationBar, setNotificationBar] = useState(false); */
    
    const services = t('SERVICES_PAGE').SERVICES;
    const servicePackages = t('SERVICES_PAGE').SERVICES_PACKAGES;
    
    const showCarteMenu = () => {
        setCarteMenu(true);
        const element = document.getElementById('serviceSection0');
        if (element) {
          element.scrollIntoView({behavior: "smooth"});
        }

        /* setNotificationBar(true); */
    }

    /* useEffect(() => {
        setTimeout(() => {
            if(notificationBar) {
                setNotificationBar(false);
            }
        }, 3000);
    }, [notificationBar]); */

    /* const closeNotificationBar = () => {
        setNotificationBar(false);
    } */

    let initialSelectedSubservices = [];
    services.forEach(service => {
        let subservices = service.SUBSERVICES;
        subservices.forEach(subservice => {
            initialSelectedSubservices = [...initialSelectedSubservices, { service: services.findIndex((obj) => obj === service), subservice: subservices.findIndex((obj) => obj === subservice), selected: false}];
        });
    });

    
    const [selectedSubservices, setSelectedSubservices] = useState(initialSelectedSubservices);
    const [selectedPackage, setSelectedPackage] = useState(-1);
    
    const onChangeSelectionSubservice = (index) => {
        let newSelectedSubservices = [...selectedSubservices];
        newSelectedSubservices[index] = {service: selectedSubservices[index].service, subservice: selectedSubservices[index].subservice, selected: !selectedSubservices[index].selected};
        setSelectedSubservices(newSelectedSubservices);
    }

    const selectPackage = (index) => {
        if(!carteMenu) {
            setCarteMenu(true);
        }
        setSelectedPackage(index);
    }
    
    const unselectPackage = () => {
        setSelectedPackage(-1);
    }

    const changeShowCart = () => {
        setShowCart(!showCart);
        (!carteMenu) && showCarteMenu();
    }
    
    return(
        <Fragment>
            <Hero />
            <PackagesSection 
                title={servicePackages.TITLE}
                titleSpan={servicePackages.TITLE_ITALIC}
                text={servicePackages.TEXT}
                servicePackages={servicePackages.PACKAGES}
                selectPackage={selectPackage}
                unselectPackage={unselectPackage}
                selectedPackage={selectedPackage}
            />
            {services.map(service => (
                <ServiceSection 
                    id={'serviceSection' + services.findIndex((obj) => obj.TITLE + obj.TITLE_ITALIC === service.TITLE + service.TITLE_ITALIC )}
                    key={service.TITLE + service.TITLE_ITALIC}
                    title={service.TITLE}
                    titleSpan={service.TITLE_ITALIC}
                    description={service.TEXT}
                    subservices={service.SUBSERVICES}
                    onChangeSelectionSubservice={onChangeSelectionSubservice}
                    selectedSubservices={selectedSubservices}
                    carteMenu={carteMenu}
                    serviceId={services.findIndex((obj) => obj === service)}
                />
            ))}
            {/* <CarteMenuSection  
                title={t('SERVICES_PAGE').CARTE_MENU.TITLE}
                titleSpan={t('SERVICES_PAGE').CARTE_MENU.TITLE_ITALIC}
                text={t('SERVICES_PAGE').CARTE_MENU.TEXT}
                buttonText={t('SERVICES_PAGE').CARTE_MENU.BUTTON_TEXT}
                showCarteMenu={showCarteMenu}
                serviceId={services[0].TITLE+services[0].TITLE_ITALIC}
            /> */}

            <CarteMenuCounter onClick={changeShowCart} carteMenu={carteMenu ? 1 : 0}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.988 77.069">
                    <g id="Group_110" data-name="Group 110" transform="translate(1473.231 -186.709)">
                        <path id="Path_54" data-name="Path 54" d="M-1387.562,464.333a8.258,8.258,0,0,0-8.19,8.252,8.209,8.209,0,0,0,8.234,8.19,8.263,8.263,0,0,0,8.21-8.233A8.31,8.31,0,0,0-1387.562,464.333Z" transform="translate(-60.559 -216.997)" fill="#fff"/>
                        <path id="Path_55" data-name="Path 55" d="M-1228.568,464.239a8.245,8.245,0,0,0-8.164,8.273,8.193,8.193,0,0,0,8.25,8.167,8.209,8.209,0,0,0,8.168-8.253A8.263,8.263,0,0,0-1228.568,464.239Z" transform="translate(-184.853 -216.923)" fill="#fff"/>
                        <path id="Path_56" data-name="Path 56" d="M-1455.959,197.507l-3.492-9.959-13.481-.839-.3,4.8,10.3.641,13.089,37.326a8.633,8.633,0,0,0-1.519,4.633,8.888,8.888,0,0,0,7.5,9.2l.188.03h38.407v-4.8h-38.006a4.086,4.086,0,0,1-3.288-4.261,3.364,3.364,0,0,1,3.3-3.392l33.584-3.053c5.364-.29,9.395-2.9,10.787-7,.788-2.318,2.544-9.749,3.827-15.174.461-1.948.858-3.631,1.084-4.534l.738-2.953Z" fill="#fff"/>
                    </g>
                    <text x={(selectedPackage !== -1) ? (1+selectedSubservices.filter((obj) => obj.selected).length < 10) ? 41 : 35 : (selectedSubservices.filter((obj) => obj.selected).length < 10) ? 41 : 35} y="33" fill="#304962" fontSize={'1.5rem'}>{(selectedPackage !== -1 || selectedSubservices.filter((obj) => obj.selected).length !== 0) ? (selectedPackage !== -1) ? 1+selectedSubservices.filter((obj) => obj.selected).length : selectedSubservices.filter((obj) => obj.selected).length : ''}</text>
                </svg>
            </CarteMenuCounter>

            <CarteMenuCart showCart={showCart ? 1 : 0}>
                <h3>{t('SERVICES_PAGE').CARTE_MENU.CART_TITLE}</h3>
                {(selectedPackage === -1 && selectedSubservices.filter((obj) => obj.selected).length === 0) && <p>{t('SERVICES_PAGE').CARTE_MENU.NOTIFICATION_TEXT}</p>}
                <ul>
                    {(selectedPackage !== -1) && <li>{servicePackages.PACKAGES[selectedPackage].TITLE}</li>}
                    {selectedSubservices.filter((obj) => obj.selected).map(subservice => (
                        <li key={services[subservice.service].SUBSERVICES[subservice.subservice].TITLE}>{services[subservice.service].SUBSERVICES[subservice.subservice].TITLE}</li>
                    ))}
                </ul>
                <CartButton to={'/' + language + '/contact'} onClick={() => onSubmitCart()} disabled={(selectedPackage === -1 && selectedSubservices.filter((obj) => obj.selected).length === 0)}>{t('SERVICES_PAGE').CARTE_MENU.CART_BUTTON_TEXT}</CartButton>
                <CloseCartButton onClick={changeShowCart}></CloseCartButton>
            </CarteMenuCart>

            {/* <CarteMenuNotificationBar notificationBar={notificationBar ? 1 : 0}>{t('SERVICES_PAGE').CARTE_MENU.NOTIFICATION_TEXT} <span onClick={closeNotificationBar}>X</span></CarteMenuNotificationBar> */}

        </Fragment>
    );
}

export { Services };