import { Link } from "react-router-dom";
import { LogoContainer } from "./HeaderLogo.styles";
import { MainContext } from '../../Context';
import { useContext, useRef } from 'react';

const HeaderLogo = () => {

    const {
        language
    } = useContext(MainContext);
    const ref = useRef();

    return (
        <LogoContainer
        ref={ref}>
            <Link to={'/' + language + '/'} onClick={() => window.scrollTo(0, 0)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.17 209.17">
                    <g>
                        <g>
                            <polygon fill="#1d3045" points="74.83 28.6 98.67 52.44 93.89 57.22 87.03 64.08 92.94 69.99 104.56 58.35 104.58 58.33 115.84 69.59 121.76 63.7 86.73 28.68 104.31 11.56 122.4 28.71 109.34 41.77 115.25 47.68 134.36 28.54 104.23 0 74.83 28.6"/>
                            <polygon fill="#1d3045" points="68.16 82.95 28.68 122.43 11.56 104.85 28.71 86.76 41.82 99.88 47.73 93.97 28.54 74.8 0 104.93 28.6 134.34 52.44 110.5 63.7 121.76 69.59 115.84 58.33 104.58 74.05 88.86 68.16 82.95"/>
                            <polygon fill="#1d3045" points="180.57 74.83 156.73 98.67 156.73 98.67 140.63 82.57 134.71 88.48 150.36 105.07 139.18 116.22 145.09 122.13 180.49 86.73 197.61 104.31 180.46 122.4 167.37 109.31 161.46 115.2 180.62 134.36 209.16 104.23 180.57 74.83"/>
                            <polygon fill="#1d3045" points="126.22 141 120.31 135.12 104.58 150.84 88.48 134.71 82.57 140.63 122.43 180.49 104.85 197.61 86.76 180.46 99.8 167.42 93.91 161.51 74.8 180.62 104.93 209.16 134.34 180.57 110.5 156.73 126.22 141"/>
                            <path fill="#9f9268" d="M162.72,60.72A13,13,0,0,0,164,59.27a15.38,15.38,0,0,0,21.58-21.91s-.11-.08-.14-.13.11-.08.14-.14A12.37,12.37,0,0,0,168,19.59a.6.6,0,0,1-.13.14c-.06-.06-.08-.11-.14-.14a15.38,15.38,0,0,0-21.9,21.59,12.09,12.09,0,0,0-1.45,1.23A13,13,0,0,0,141.81,57l-5.4,5.41a4.48,4.48,0,0,0-6.29,6.29L112.38,86.39l-7.8,7.79-7.82-7.82L79,68.62a4.48,4.48,0,0,0-6.29-6.29l-5.4-5.4a13,13,0,0,0-2.61-14.54,12.18,12.18,0,0,0-1.45-1.24,15.37,15.37,0,0,0-21.9-21.58,1.54,1.54,0,0,1-.14.13c0-.05-.08-.11-.13-.13A12.37,12.37,0,1,0,23.6,37.06c0,.06.11.09.13.14a.8.8,0,0,0-.13.13A15.38,15.38,0,0,0,45.18,59.24,12.87,12.87,0,0,0,61,63.3l5.4,5.4A4.48,4.48,0,0,0,72.65,75L90.39,92.73l7.82,7.82L87.27,111.49l-2,2h0l-8.22,8.23L49.91,148.85,35.45,163.31l-7.55,7.55,6.37,6.37,7.55-7.55,4,4,3.84-3.84,4,4-3.84,3.85,6,6,14.46-14.46-6-6L60.39,167l-4-4,3.84-3.84-4-4,30.91-30.91,5.24-5.24h0l4.68-4.68,4.17-4.16,3.33-3.34,3.33,3.34,4.17,4.16,4.68,4.68h0l5.24,5.24,30.91,30.91-4,4L152.8,163l-4,4-3.89-3.84-6,6,14.46,14.46,6-6-3.85-3.85,4-4,3.84,3.84,4-4,7.56,7.55,6.37-6.37-7.56-7.55-14.46-14.46-27.17-27.17-8.23-8.23h0l-2-2L111,100.55l7.82-7.82L136.51,75a4.48,4.48,0,0,0,6.29-6.29l5.41-5.4A12.89,12.89,0,0,0,162.72,60.72Zm10.89-35.54a4.5,4.5,0,1,1,0,6.37A4.47,4.47,0,0,1,173.61,25.18ZM169.36,43a7.51,7.51,0,1,1,0,10.62A7.51,7.51,0,0,1,169.36,43ZM151.57,25.18a7.51,7.51,0,1,1,0,10.62A7.52,7.52,0,0,1,151.57,25.18ZM150,48a5.06,5.06,0,1,1,0,7.15A5.08,5.08,0,0,1,150,48ZM29.22,25.18a4.5,4.5,0,1,1,0,6.37A4.47,4.47,0,0,1,29.22,25.18ZM39.81,53.57a7.51,7.51,0,1,1,0-10.62A7.51,7.51,0,0,1,39.81,53.57ZM47,25.18A7.51,7.51,0,1,1,47,35.8,7.53,7.53,0,0,1,47,25.18Zm12.21,30a5.06,5.06,0,1,1,0-7.15A5.06,5.06,0,0,1,59.19,55.15Z"/>
                        </g>
                    </g>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377.42 184.7">
                    <g>
                        <g>
                            <path fill="#1d3045" d="M18.88,56.48v.15c0,28.71,17.34,52,38.83,52a47.68,47.68,0,0,0,35-14.59l3.69,3.69a56.46,56.46,0,0,1-38.68,15.35C26.4,113.12,0,87.79,0,56.48,0,25.32,26.4,0,57.71,0A56.32,56.32,0,0,1,96.39,15.19L92.7,19a47.71,47.71,0,0,0-35-14.58C36.22,4.45,18.88,27.78,18.88,56.48Z"/>
                            <path fill="#1d3045" d="M158.09,4.6,209.2,112.35H185.71L169.91,79.2H122.63l-15.81,33.15h-4.45l41.75-87.64L155.79,0Zm9.67,70.15L146.27,29.32,124.78,74.75Z"/>
                            <path fill="#1d3045" d="M300.37,29.16v1.08c0,14-11.36,28.54-34.23,28.54h-26.7v53.57h-18V.61h44.66C289,.61,300.37,15.19,300.37,29.16Zm-19.19.62c0-12.28-7.67-24.56-23-24.56H239.44V54.33h18.72C273.51,54.33,281.18,42.05,281.18,29.78Z"/>
                            <path fill="#1d3045" d="M317.25.61h60.17V5.22H335.21V54.33h32.54v4.45H335.21V107.9h42.21v4.45H317.25Z"/>
                            <path fill="#1d3045" d="M41,142.79v41.63H34.39V156L21.32,177.24,17,184.14l-.79-1.29L1.63,159.29v25.13H0V142.79l1.63,2.64,18.85,30.46,13.91-22.55Z"/>
                            <path fill="#1d3045" d="M65.81,145l18.68,39.38H75.91L70.13,172.3H52.85l-5.78,12.12H45.45l15.25-32,4.27-9Zm3.53,25.63-7.85-16.6-7.85,16.6Z"/>
                            <path fill="#1d3045" d="M115.18,143.58h1.63V184.7L89.49,155v29.45H87.86V143.35l27.32,29.74Z"/>
                            <path fill="#1d3045" d="M141.66,145l18.69,39.38h-8.59L146,172.3H128.71l-5.78,12.12H121.3l15.26-32,4.26-9Zm3.54,25.63-7.86-16.6-7.85,16.6Z"/>
                            <path fill="#1d3045" d="M203.27,164a20.67,20.67,0,0,1-11.84,18.74c-.06,0-.11,0-.17.11h-.06a.26.26,0,0,0-.16.11H191c-.06,0-.11.06-.17.06h-.05c-.06,0-.12,0-.17.11h-.06c-.05.05-.11.05-.17.11h-.05c-.06,0-.11.06-.17.06h-.06c-.05,0-.11,0-.17.11h0c-.06,0-.11.05-.17.05h-.06a9.58,9.58,0,0,1-1.79.57c0,.05-.06.05-.06.05h-.17a.05.05,0,0,1-.05.06h-.17l-.06.05h-.16c-.06.06-.06.06-.12.06h-.16l-.06.06h-.17a.17.17,0,0,0-.11.05h-.23a.18.18,0,0,1-.16.06H186a.19.19,0,0,0-.11.05h-.22c-.06,0-.12.06-.17.06h-.23c-.05,0-.11,0-.11.06h-.39c-.06,0-.11,0-.11,0h-.56a.19.19,0,0,0-.12.06h-1.4c-11.44,0-21.15-9.26-21.15-20.7s9.71-20.65,21.15-20.65a20.81,20.81,0,0,1,14.14,5.56l-1.4,1.4A17.22,17.22,0,0,0,182.62,145c-9.42,0-14.19,8.53-14.19,19v.05c0,13.47,6.34,19,14.19,19,7.46,0,13.24-5.27,13.69-17.45H182.62V164Z"/>
                            <path fill="#1d3045" d="M208.88,143.58h22v1.68H215.44v18h11.9v1.63h-11.9v17.95h15.43v1.63h-22Z"/>
                            <path fill="#1d3045" d="M278,142.79v41.63h-6.56V156l-13.08,21.26-4.32,6.9-.78-1.29-14.59-23.56v25.13h-1.62V142.79l1.62,2.64,18.85,30.46,13.92-22.55Z"/>
                            <path fill="#1d3045" d="M285.86,143.58h22v1.68H292.42v18h11.89v1.63H292.42v17.95h15.43v1.63h-22Z"/>
                            <path fill="#1d3045" d="M341.34,143.58H343V184.7L315.65,155v29.45H314V143.35l27.32,29.74Z"/>
                            <path fill="#1d3045" d="M377.42,143.58v1.68H366.25v39.16h-6.5V145.26H348.58v-1.68Z"/>
                        </g>
                    </g>
                </svg>
            </Link>
        </LogoContainer>
    );
}

export { HeaderLogo }