import { Route, Routes } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Home } from './Home';
import { AboutUs } from './AboutUs';
import { Contact } from './Contact';
import { NotFound } from './NotFound';
import { PrivacyPolicy } from './PrivacyPolicy';
import { Services } from './Services';

const LocalizedRoutes = () => {

    const supportedLanguages = ["en", "es"];

    let { locale } = useParams();

    if (supportedLanguages.includes(locale)) {
        return(
            <Routes>
              <Route exact path={""} element={<Home />}/>
              <Route path={`about-us`} element={<AboutUs />}/>
              <Route path={`services`} element={<Services />}/>
              <Route path={`contact`} element={<Contact />}/>
              <Route path={`privacy-policy`} element={<PrivacyPolicy />}/>
              <Route path={`*`} element={<NotFound />}/>
            </Routes>
          );
    } else {
        return(
            <NotFound />
        );
    }
  }

  export { LocalizedRoutes };