import { HashLink } from "react-router-hash-link";
import styled, { css } from "styled-components/macro";

export const CarteMenuContainer = styled.section`
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 3rem;
    width: 80vw;
    position: relative;

    @media only screen and (min-width: 769px) {
        width: 70%;
    }

    @media only screen and (min-width: 1024px) {
        width: 50%;
    }
`;

export const CarteMenuTitle = styled.h2`
    color: #707070;
    margin: 0;
    width: 80%;
    box-sizing: border-box;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: center;
    margin-bottom: 2rem;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility, directionFrom }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            transform: ${visibility ? 'translate(0px, 0px)' : directionFrom };
        `;
    }}
`;

export const CarteMenuDescription = styled.p`
    width: 80%;
    margin: 0;
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    white-space: pre-line;
`;

export const CarteMenuButton = styled(HashLink)`
    padding: 1rem;
    margin-top: 1rem;
    background-color: #707070;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    transition: background-color .3s ease-in-out;
    
    &:hover {
        background-color: #5c5c5c;
    }
`;