import { useTranslation } from "react-i18next";
import { PackageCardContainer, PackageCardImage, SelectButton, ServiceOnPackage, ServicesOnPackage, PackageTitle} from "./PackageCard.styles";

const PackageCard = ({ title, description, imageURL, imageAlt, id, isCurrentPackage, isPrevPackage, isNextPackage, moveLeft, moveRight, moveSliderLeft, moveSliderRight, selectedPackage, selectPackage, unselectPackage }) => {

    const { t } = useTranslation();

    return(
        <PackageCardContainer 
            id={id} 
            isCurrentPackage={(isCurrentPackage) ? 1 : 0} 
            isNextPackage={(isNextPackage) ? 1 : 0} 
            isPrevPackage={(isPrevPackage) ? 1 : 0}
            moveRight={(moveRight) ? 1 : 0}
            moveLeft={(moveLeft) ? 1 : 0}
            onClick={(isNextPackage) ? () => moveSliderRight() : (isPrevPackage) ? () => moveSliderLeft() : () => {return false;}}>

            <PackageCardImage
            isCurrentPackage={(isCurrentPackage) ? 1 : 0}>
                <img src={imageURL} alt={imageAlt}/>
                <PackageTitle isCurrentPackage={(isCurrentPackage) ? 1 : 0}>{title}</PackageTitle>
                <figcaption>
                    <ServicesOnPackage>
                        {description.map(service => (
                            <ServiceOnPackage key={service.TITLE}>{service.TITLE}
                                <ul>
                                {service.SUBSERVICES.map(subservice => (
                                    <li key={subservice}>{subservice}</li>
                                ))}
                                </ul>
                            </ServiceOnPackage>
                        ))}
                    </ServicesOnPackage>
                    {(selectedPackage === id) 
                    ? <SelectButton onClick={() => unselectPackage()} isCurrentPackage={(isCurrentPackage) ? 1 : 0}>{t('SERVICES_PAGE').SERVICES_PACKAGES.UNSELECT_BUTTON}</SelectButton>
                    : <SelectButton onClick={() => selectPackage(id)} isCurrentPackage={(isCurrentPackage) ? 1 : 0}>{t('SERVICES_PAGE').SERVICES_PACKAGES.SELECT_BUTTON}</SelectButton>
                    }
                </figcaption>
            </PackageCardImage>
        </PackageCardContainer>
    );
}

export { PackageCard };