import styled, { css } from 'styled-components/macro';

export const MenuTriggerContainer = styled.a`

    &:hover {
        cursor: pointer;
    }

    @media only screen and (min-width: 1200px) {
        display: none;
    }
`;

export const MenuTriggerIcon = styled.i`
    position: relative;
    z-index: 10;
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    line-height: 2rem;
    left: 0;
    top: 0;
    transform-origin: center center;
    background: #707070;
    transition: rotate .4s cubic-bezier(.77,0,.175,1),height .4s cubic-bezier(.77,0,.175,1),margin .4s cubic-bezier(.77,0,.175,1);

    &::after, &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2px;
        left: 0;
        position: absolute;
        background: #707070;
        transition: rotate .4s cubic-bezier(.77,0,.175,1),left .4s cubic-bezier(.77,0,.175,1),top .4s cubic-bezier(.77,0,.175,1),bottom .4s cubic-bezier(.77,0,.175,1);
    }

    &::before {
        top: -.5rem;
    }

    &::after {
        bottom: -.5rem;
    }

    ${({ opened }) => {
        return css`
            height: ${opened ? '0' : '2px'};
            rotate: z ${opened ? '90deg' : '0deg'};

            &::before {
                rotate: z ${opened ? '45deg' : '0deg'};
                top: ${opened ? '-1px' : '-.5rem'};
            }

            &::after {
                rotate: z ${opened ? '-45deg' : '0deg'};
                bottom: ${opened ? '-1px' : '-.5rem'};
            }
        `;
    }}

`;