import { useContext, useEffect } from "react";
import { MainContext } from "../Context";

const NotFound = () => {

    const {
        onChangeSection,
    } = useContext(MainContext);

    useEffect(() => {
        onChangeSection('Not Found');
    });

    return(
        <div>
            Not Found
        </div>
    );
}

export { NotFound };