import { MenuTriggerIcon, MenuTriggerContainer } from "./MenuTrigger.styles";

const MenuTrigger = ({ MenuOpened, opened }) => {
    
    return (
        <MenuTriggerContainer onClick={MenuOpened}>
            <MenuTriggerIcon opened={opened}></MenuTriggerIcon>
        </MenuTriggerContainer>
    );
}

export { MenuTrigger };