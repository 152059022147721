import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

export const StyledImageContainer = styled(Link)`

    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
    transition: all .5s ease-in-out;
    overflow: hidden;

    &:first-of-type, &:nth-of-type(5) {
        grid-area: span 2 / span 2;

        & figcaption {
            font-size: 2rem;
        }
    }

    &:nth-of-type(4), &:nth-of-type(8) {
        grid-area: span 1 / span 2;
    }

    & img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-delay: .5s;
    }

    ${({ visibility, directionfrom }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: ${visibility ? '0 0' : directionfrom };
        `;
    }}

    @keyframes zoom-in {
        to {
            scale: 1.5;
        }
    }

    @media only screen and (min-width: 769px) {
        &:first-of-type, &:nth-of-type(5) {
            & figcaption {
                font-size: 3rem;
            }
        }

        &:nth-of-type(4), &:nth-of-type(8) {
            & figcaption {
                font-size: 2rem;
            }
        }

        &:hover img {
            animation-name: zoom-in;
        }
    }

    @media only screen and (min-width: 1200px) {
        & figcaption {
            font-size: 2rem;
        }

        &:first-of-type, &:nth-of-type(5) {
            & figcaption {
                font-size: 3.5rem;
            }
        }

        &:nth-of-type(4), &:nth-of-type(8) {
            & figcaption {
                font-size: 2.5rem;
            }
        }
    }
`;

export const StyledFigcaption = styled.figcaption`

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .8);
    width: 100%;
    height: fit-content;
    padding: 1rem 0;
    transition: all .3s .5s ease-in-out;
    font-family: 'Minion Pro', sans-serif;
    font-weight: normal;
    font-style: italic;
    font-size: 1.5rem;
    color: #707070;

    @media only screen and (max-width: 768px) {
        ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
        `;
        }}
    }

    @media only screen and (min-width: 769px) {
        height: 100%;
        padding: 0;
        opacity: 0;
        transition: all .5s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`;