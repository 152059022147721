import { useTranslation } from "react-i18next";
import { FooterNav } from "./FooterNav";
import { FooterContainer, StyledTitle, StyledSocialNav, StyledCopyRightText } from "./Footer.styles";
import { FooterLogo } from "./FooterLogo";

const Footer = () => {

    const { t } = useTranslation();

    return (
        <FooterContainer>
            <StyledTitle>{t('FOOTER').MAIN_PHRASE} <span>{t('FOOTER').MAIN_PHRASE_ITALIC}</span></StyledTitle>
            <StyledSocialNav>
                <a href='https://www.instagram.com/capemanagement' target="_blank" rel="noopener noreferrer">
                    <svg id="vgotBX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.334 20.344">
                        <g id="Group_498" data-name="Group 498" transform="translate(0 0)">
                            <path id="Path_454" data-name="Path 454" d="M370.2,411.244a1.122,1.122,0,1,0-1.135-1.112,1.109,1.109,0,0,0,1.107,1.112Z" transform="translate(-354.94 -405.039)" fill="#f7f9f6"/>
                            <path id="Path_455" data-name="Path 455" d="M346.673,412.885v-4.419a5.618,5.618,0,0,1,4.691-5.658,5.418,5.418,0,0,1,1-.083q4.471-.01,8.942,0a5.63,5.63,0,0,1,5.685,5.576v.093c.008,3.052.02,6.1-.006,9.155a5.636,5.636,0,0,1-5.455,5.5,1.14,1.14,0,0,1-.341-2.255,1.127,1.127,0,0,1,.269-.008,3.357,3.357,0,0,0,3.172-2.568,3.724,3.724,0,0,0,.1-.863q.01-4.46,0-8.918a3.351,3.351,0,0,0-3.256-3.444c-.062,0-.125,0-.188,0q-4.457-.01-8.916,0a3.349,3.349,0,0,0-3.431,3.265c0,.055,0,.11,0,.165q-.012,4.472,0,8.944a3.352,3.352,0,0,0,3.285,3.42c.044,0,.086,0,.13,0,1.464.009,7.342,0,8.805,0a1.127,1.127,0,0,1,1.208,1.04c0,.027,0,.055,0,.083a1.1,1.1,0,0,1-1.073,1.134h-.016c-1.614.01-7.643.043-9.255-.009a5.618,5.618,0,0,1-5.348-5.486c-.018-1.552,0-3.1,0-4.657Z" transform="translate(-346.667 -402.719)" fill="#f7f9f6"/>
                            <path id="Path_456" data-name="Path 456" d="M354.734,415.849a5.079,5.079,0,1,1,5.068,5.093A5.079,5.079,0,0,1,354.734,415.849Zm7.895.016a2.8,2.8,0,1,0,0,.032v-.032Z" transform="translate(-349.647 -405.698)" fill="#f7f9f6"/>
                            <path id="Path_457" data-name="Path 457" d="M370.2,411.244a1.122,1.122,0,1,1,1.111-1.135,1.109,1.109,0,0,1-1.083,1.134Z" transform="translate(-354.941 -405.039)" fill="#f7f9f6"/>
                        </g>
                    </svg>
                </a>
                <a href='https://www.facebook.com/capemanagement' target="_blank" rel="noopener noreferrer">
                    <svg id="Ldl3ws" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.884 20.483">
                        <g id="Group_497" data-name="Group 497" transform="translate(0 0)">
                            <path id="Path_452" data-name="Path 452" d="M398.607,423.09V412.443h3.138l.235-3.061H398.6v-2.339a1,1,0,0,1,.745-1.043,3.144,3.144,0,0,1,1.48-.076c.347.046.689.126,1.047.189l.526-3.118a.707.707,0,0,0-.107-.035c-.43-.076-.858-.167-1.291-.22a14.842,14.842,0,0,0-3-.1,3.512,3.512,0,0,0-1.995.778,3.3,3.3,0,0,0-1.155,1.688,7.02,7.02,0,0,0-.219,1.756c-.012.666,0,1.332,0,2v.532h-2.114v3.057h2.114v10.64Z" transform="translate(-392.515 -402.607)" fill="#f7f9f6"/>
                            <path id="Path_453" data-name="Path 453" d="M398.607,423.089H394.63V412.45h-2.114v-3.057h2.114v-.532c0-.665-.008-1.332,0-2a7.017,7.017,0,0,1,.219-1.756,3.3,3.3,0,0,1,1.155-1.688,3.513,3.513,0,0,1,1.995-.778,14.847,14.847,0,0,1,3,.1c.433.053.861.144,1.291.22a.707.707,0,0,1,.107.035l-.526,3.118c-.358-.063-.7-.143-1.047-.189a3.144,3.144,0,0,0-1.48.076,1,1,0,0,0-.745,1.043v2.339h3.379l-.235,3.061h-3.138Z" transform="translate(-392.515 -402.607)" fill="#f7f9f6"/>
                        </g>
                    </svg>
                </a>
            </StyledSocialNav>
            <FooterLogo />
            <FooterNav />
            <StyledCopyRightText>© 2022 CAPE MANAGEMENT</StyledCopyRightText>
        </FooterContainer>
    );
}

export { Footer };