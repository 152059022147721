import styled, { css } from "styled-components/macro";

export const PackagesContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
`;

export const PackagesTitle = styled.h2`
    color: #707070;
    margin: 0;
    width: 80%;
    box-sizing: border-box;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: center;
    margin-bottom: 2rem;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}
`;

export const PackagesDescription = styled.p`
    width: 80%;
    margin: 0;
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    white-space: pre-line;

    @media only screen and (min-width: 769px) {
        grid-column: span 3;
    }
`;

export const PackagesRoulette = styled.div`
    position: relative;
    height: 26rem;
    width: 100vw;
    padding: 3rem 10vw;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    @media only screen and (min-width: 769px) {
        position: relative;
        height: 26rem;
        padding: 0;

        & span {
            color: #ccc;
            font-size: 3rem;
            cursor: pointer;
            position: relative;
            opacity: 0.75;
            transition: all 300ms ease-in-out;
        }

        & span:nth-of-type(1) {
            float: left;
        }

        & span:nth-of-type(2) {
            float: right;
        }

        & span:hover {
            opacity: 1;
        }

        & span svg {
            height: 3rem;
            transition: all 300ms ease-in-out;
        }

        & span:hover svg {
            scale: 1.2;
        }
    }
    
    @media only screen and (min-width: 1024px) {

        height: 28rem;
        
    }

    @media only screen and (min-width: 1200px) {
        width: 80%;
    }

    @keyframes animationRight {
        from {
            translate: 0 0;
        }
        to {
            translate: calc(-80vw - 1rem) 0;
        }
    }

    @keyframes animationLeft {
        from {
            translate: 0 0;
        }
        to {
            translate: calc(80vw + 1rem) 0;
        }
    }

    @media only screen and (min-width: 600px) {
        @keyframes animationRight {
            from {
                translate: 0 0;
            }
            to {
                translate: calc(-60vw - 1rem) 0;
            }
        }

        @keyframes animationLeft {
            from {
                translate: 0 0;
            }
            to {
                translate: calc(60vw + 1rem) 0;
            }
        }
    }

    @media only screen and (min-width: 769px) {
        @keyframes animationRight {
            from {
                translate: 0 0;
            }
            to {
                translate: calc(-30vw - 1rem) 0;
            }
        }

        @keyframes animationLeft {
            from {
                translate: 0 0;
            }
            to {
                translate: calc(30vw + 1rem) 0;
            }
        }
    }

`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    padding: 0 5%;
    box-sizing: border-box;
    z-index: 2;
    pointer-events: none; 

    & span {
        height: 2rem;
        pointer-events: auto; 
        & svg {
            height: inherit;
        }
    }

    @media only screen and (min-width: 769px) {
        width: 100%;

        & span {
        height: 3rem;
        & svg {
            height: inherit;
        }
    }
    }
`;

export const PackageTitle = styled.h3`
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    font-size: 2rem;
    width: 15rem;
    padding-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1rem;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        background-color: #707070;
        transition: all .5s ease-in-out;
        
        ${({ visibility }) => {
        return css`
            width: ${visibility ? '120%' : '0%'};
            left: ${visibility ? '-10%' : '50%' };
        `;
    }}
    }

    @media only screen and (min-width: 1024px) {
        width: 20rem;
    }
`;
