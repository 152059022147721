import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

export const StyledServicesSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    position: relative;
    justify-items: center;
    align-items: center;
    padding: 3rem 0;
`;

export const StyledTitle = styled.h2`
    box-sizing: border-box;
    text-align: center;
    padding: 0 3rem;
    margin: 0;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #707070;
    font-size: 1.5rem;
    transition: all .5s ease-in-out;

    & span {
        display: inline-block;
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
        transition: all .5s ease-in-out;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: ${visibility ? '0px' : '-2.5rem' } 0;

            & span {
                translate: ${visibility ? '0px' : '5rem' } 0;
            }
        `;
    }}

    @media only screen and (min-width: 425px) {
        font-size: 2rem;
    }
`;

export const StyledImageGallery = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 49.75vw);
    grid-auto-rows: 49.75vw;
    grid-gap: 0.5vw;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media only screen and (min-width: 600px) {
        grid-template-columns: repeat(4, 24.5vw);
        grid-auto-rows: 24.5vw;
        grid-gap: .66666vw;
    }
`;

export const StyledServicesLink = styled(Link)`
    width: fit-content;
    margin: auto;
    text-align: center;
    text-decoration: none;
    color: #707070;
    position: relative;
    text-transform: uppercase;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;

    &::after {
        position: absolute;
        content: '';
        height: 2px;
        margin: 0;
        left: 0%;
        bottom: -2px;
        background: #707070;
        opacity: .8;
        transition: .5s;
        width: 100%;
        transform-origin: center;
    }

    @media only screen and (min-width: 769px) {
        grid-column-start: 1;
        grid-column-end: 3;
    
        &::after {
            width: 100%;
            left: 0%;
        }

        &:hover::after {
            width: 0%;
            left: 50%;
        }
    }
`;

