import { PrivacyPolicyContainer, PrivacyPolicyTitle, PrivacyDetails, PrivacyPolicySubTitle, PrivacyPolicyParagraph, PrivacyPolicyList, PrivacyPolicyListItem } from "./PrivacyPolicy.styles";
import { useRef, useContext, useEffect, Fragment } from "react";
import { MainContext } from "../Context";
import { useTranslation } from "react-i18next";
import useOnScreen from "../Hooks/useOnScreen";


const PrivacyPolicy = () => {

    const {
        onChangeSection,
        language
    } = useContext(MainContext);

    const titleRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);

    const { t } = useTranslation();

    useEffect(() => { 
        onChangeSection(t('PAGES_NAME').PRIVACY_POLICY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[language]);

    const articles = t('PRIVACY_POLICY_PAGE').ARTICLES;

    return(
        <PrivacyPolicyContainer>
            <PrivacyPolicyTitle  ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{t('PRIVACY_POLICY_PAGE').TITLE} <span>{t('PRIVACY_POLICY_PAGE').TITLE_ITALIC}</span></PrivacyPolicyTitle>
            <PrivacyDetails>{t('PRIVACY_POLICY_PAGE').DETAILS} </PrivacyDetails>
            <PrivacyPolicyParagraph>{t('PRIVACY_POLICY_PAGE').TEXT}</PrivacyPolicyParagraph>
            {articles.map(article => (
                <Fragment key={article.TITLE}>
                    <PrivacyPolicySubTitle>{article.TITLE}</PrivacyPolicySubTitle>
                    {article.TEXT.map(text => (
                        (Array.isArray(text))
                        ?   <PrivacyPolicyList>{text.map(item => (
                                <PrivacyPolicyListItem>{item}</PrivacyPolicyListItem>
                            ))}</PrivacyPolicyList>
                        :  <PrivacyPolicyParagraph>{text}</PrivacyPolicyParagraph>
                        ))}
                </Fragment>
            ))}
        </PrivacyPolicyContainer>
    );
}

export { PrivacyPolicy };

