import { Fragment, useContext, useEffect } from "react";
import { MainContext } from "../Context";
import { Hero } from "./Hero";
import { AboutUsSection } from "./AboutUsSection";
import { ServicesSections } from "./ServicesSection";
import { useTranslation } from "react-i18next";

const Home = () => {

    const {
        onChangeSection,
        language
    } = useContext(MainContext);

    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    }, []);

    useEffect(() => {
        onChangeSection(t('PAGES_NAME').HOME);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return (
        <Fragment>
            <Hero/>
            <AboutUsSection />
            <ServicesSections />
        </Fragment>
    );
}

export { Home };