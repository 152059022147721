import { useTranslation } from "react-i18next";
import { GalleryImage } from "./GalleryImage";
import { StyledServicesSectionContainer, StyledTitle, StyledServicesLink, StyledImageGallery } from "./ServicesSection.styles";
import { useRef, useContext } from "react";
import { MainContext } from "../../Context";
import useOnScreen from "../../Hooks/useOnScreen";

const ServicesSections = () => {

    const {
        language
    } = useContext(MainContext);

    const titleRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);

    const { t } = useTranslation();

    let galleryImagesData = t('HOMEPAGE').GALLERY_IMAGES;

    return (
        <StyledServicesSectionContainer>
            <StyledTitle ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{t('HOMEPAGE').SERVICES_SECTION_TITLE}<br/><span>{t('HOMEPAGE').SERVICES_SECTION_TITLE_ITALIC}</span></StyledTitle>
            <StyledImageGallery>
            {galleryImagesData.map(image => (
                <GalleryImage 
                    key={image.IMAGE}
                    imageURL={image.IMAGE}
                    altText={image.DESCRIPTION} 
                    imageTitle={image.TITLE}
                    serviceTitle={image.SERVICE}
                    imageLink={'/' + language + image.IMAGE_LINK}
                />
            ))}
            </StyledImageGallery>
            <StyledServicesLink to={'/' + language + '/services'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').SERVICES}</StyledServicesLink>
        </StyledServicesSectionContainer>
    );
}

export { ServicesSections };