import styled, { css } from "styled-components/macro";

export const StyledHero = styled.section`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
`;

export const StyledHeroBackground = styled.figure`
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    position: relative;

    & img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        object-position: center;
    }

    & svg {

        ${({ windowHeight, windowWidth }) => {
            return css`
                width: ${(windowWidth > windowHeight) ? '100%' : 'auto'};
                height: ${(windowHeight > windowWidth) ? '100%' : (windowWidth === windowHeight) ? '100%' :'auto'};
            `;
        }}
        object-fit: cover;
        object-position: center;
        scale: 1.1;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: background-color 1s ease-in-out;

        & path {
            transition: fill .5s ease-in-out;
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .3);
    }
`;

export const StyledHeroText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50vh;
    width: 100vw;
    padding:  0 10vw;
    box-sizing: border-box;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: normal;
    font-style: normal;
    opacity: 0;
    transition: opacity .5s ease-in-out;

    & h2, & h3 {
        margin: 0;
        color: #FBF0C4;
        text-align: center;
        letter-spacing: 5px;
        font-size: 2rem;
        margin-bottom: .5rem;
    }

    & h2 {
        font-weight: 300;
    }

    & h3 {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
        margin: 0 10px;
    }

    @media only screen and (max-width:600px){
        flex-direction: column;
    }
`;

