import { useRef, useState } from "react";
import useOnScreen from "../../Hooks/useOnScreen";
import { PackageCard } from "./PackageCard";
import { PackagesDescription, PackagesTitle, PackagesContainer, PackagesRoulette, ButtonsContainer, PackageTitle } from "./PackageSection.styles";

const PackagesSection = ({ title, titleSpan, text, servicePackages, selectPackage, unselectPackage, selectedPackage}) => {

    const titleRef = useRef();
    const packageTitleRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);
    const packageTitleIsVisible = useOnScreen(packageTitleRef);

    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [currentPackage, setCurrentPackage] = useState(0)
    const [moveRight, setMoveRight] = useState(false)
    const [moveLeft, setMoveLeft] = useState(false)

    function handleTouchStart(e) {
        setTouchStart(e.targetTouches[0].clientX);
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchMove(e) {
        setTouchEnd(e.targetTouches[0].clientX);
    }

    function handleTouchEnd(e) {
        if (touchStart - touchEnd > 100) {
            // do your stuff here for left swipe
            moveSliderRight();
        }

        if (touchStart - touchEnd < -100) {
            // do your stuff here for right swipe
            moveSliderLeft();
        }

        setTouchStart(0);
        setTouchEnd(0);
    }

    function moveSliderLeft() {
        if(!moveRight && !moveLeft) {
            setMoveLeft(true);
            setTimeout(() => {
                let newCurrentPackage = (currentPackage !== 0) ? currentPackage-1 : servicePackages.length-1;
                setCurrentPackage(newCurrentPackage); 
            }, 100);
            setTimeout(() => {
                setMoveLeft(false);
            }, 250);
        }
    }

    function moveSliderRight() {
        if(!moveRight && !moveLeft) {
            setMoveRight(true);
            setTimeout(() => {
                let newCurrentPackage = (currentPackage !== servicePackages.length-1) ? currentPackage+1 : 0;
                setCurrentPackage(newCurrentPackage); 
            }, 100);
            setTimeout(() => {
                setMoveRight(false);
            }, 250);
        }
    }

    return(
        <PackagesContainer id={title.replaceAll(' ', '')+titleSpan.replaceAll(' ', '')}>
            <PackagesTitle  ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{title} <span>{titleSpan}</span></PackagesTitle>
            <PackagesDescription>{text}</PackagesDescription>
            <PackageTitle ref={packageTitleRef} visibility={packageTitleIsVisible ? 1 : 0}>{servicePackages[currentPackage].TITLE}</PackageTitle>
            <PackagesRoulette
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                moveLeft={moveLeft}
                moveRight={moveRight}>
                {servicePackages.map(servicePackage => (
                    <PackageCard 
                        key={servicePackage.TITLE}
                        id={servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE)}
                        title={servicePackage.TITLE}
                        description={servicePackage.SERVICES_LIST}
                        imageURL={servicePackage.IMAGE_URL}
                        imageAlt={servicePackage.IMAGE_ALT}
                        isCurrentPackage={(currentPackage === servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE))}
                        isPrevPackage={(currentPackage !== 0) ? (servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE) === currentPackage-1) ? true : false : (servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE) === servicePackages.length-1) ? true : false}
                        isNextPackage={(currentPackage !== servicePackages.length-1) ? (servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE) === currentPackage+1) ? true : false : (servicePackages.findIndex(object => object.TITLE === servicePackage.TITLE) === 0) ? true : false}
                        moveLeft={moveLeft}
                        moveRight={moveRight}
                        selectPackage={selectPackage}
                        unselectPackage={unselectPackage}
                        selectedPackage={selectedPackage}
                        moveSliderLeft={moveSliderLeft}
                        moveSliderRight={moveSliderRight}
                    />
                ))}
                <ButtonsContainer>
                    <span onClick={moveSliderLeft}><svg viewBox="0 0 44 77" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.5644 9.51556C43.5171 7.56294 43.5126 4.39265 41.56 2.44002C39.6074 0.487403 36.4371 0.482937 34.4844 2.43556L1.95552 34.9645C0.00289929 36.9171 0.00289547 40.0829 1.95552 42.0356L34.4844 74.5645C36.4371 76.5171 39.6074 76.5126 41.56 74.56C43.5126 72.6074 43.5171 69.4371 41.5644 67.4845L16.1155 42.0356C14.1629 40.0829 14.1629 36.9171 16.1155 34.9645L41.5644 9.51556Z" fill="#CCCCCC"/></svg></span>
                    <span onClick={moveSliderRight}><svg viewBox="0 0 45 77" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.63551 3.44446C-0.31711 5.39709 -0.317112 8.56291 1.63551 10.5155L26.0844 34.9645C28.0371 36.9171 28.0371 40.0829 26.0844 42.0355L1.63551 66.4845C-0.317116 68.4371 -0.317113 71.6029 1.63551 73.5555L3.04444 74.9645C4.99706 76.9171 8.16289 76.9171 10.1155 74.9645L43.0444 42.0355C44.9971 40.0829 44.9971 36.9171 43.0444 34.9645L10.1155 2.03553C8.16288 0.0829092 4.99706 0.0829129 3.04444 2.03553L1.63551 3.44446Z" fill="#CCCCCC"/></svg></span>
                </ButtonsContainer>
            </PackagesRoulette>
        </PackagesContainer>
    );
}

export { PackagesSection };