import styled from "styled-components";

export const StyledLangSelector = styled.select`
    border: none;
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
    background-color: transparent;
`;