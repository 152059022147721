import { useTranslation } from "react-i18next";
import { StyledHero, StyledHeroBackground } from "./Hero.styles";

const Hero = () => {

    const { t } = useTranslation();

    return (
        <StyledHero>
            <StyledHeroBackground>
                <img src="/assets/images/about-us/hero.webp" alt={t('ABOUT_US_PAGE').ALT_HERO_IMAGE}/>
            </StyledHeroBackground>
        </StyledHero>
    );
}

export { Hero };