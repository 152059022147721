import { StyledFooterNav, StyledFooterLinkList, StyledFooterListItem } from "./FooterNav.styles";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Context";
import { useContext } from "react";
import { Link } from "react-router-dom";

const FooterNav = () => {

    const {
        language
    } = useContext(MainContext);

    const { t } = useTranslation();

    return(
        <StyledFooterNav>
            <StyledFooterLinkList>
                <StyledFooterListItem><Link to={'/'+language+'/about-us'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').ABOUT_US}</Link></StyledFooterListItem>
                <StyledFooterListItem><Link to={'/'+language+'/contact'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').CONTACT}</Link></StyledFooterListItem>
                <StyledFooterListItem><Link to={'/'+language+'/services'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').SERVICES}</Link></StyledFooterListItem>
                <StyledFooterListItem><Link to={'/'+language+'/privacy-policy'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').PRIVACY_POLICY}</Link></StyledFooterListItem>
            </StyledFooterLinkList>
        </StyledFooterNav>
    );
}

export { FooterNav };