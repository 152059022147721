import styled, { css } from "styled-components/macro";

export const StyledSubserviceContainer = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    translate: 0 0;
    scale: .9;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    background-color: #fff;

    @media only screen and (min-width: 769px) {
        max-height: 80vh;
        height: 100%;
        margin: 0;
    }

    ${({ lastSubservice, selectedSubservice }) => {
        return css`
            opacity: ${(selectedSubservice || lastSubservice) ? '1' : '0'};
            z-index: ${(selectedSubservice) ? '2' : (lastSubservice) ? '3' : '1'};
            animation-name: ${(lastSubservice) ? 'exitAnimation' : (selectedSubservice) ? 'enterAnimation' : 'none'};
            animation-delay: ${(selectedSubservice) ? '.7s' : '0'};
        `;
    }}

    @keyframes exitAnimation {
        0% {
            translate: 0% 0;
            scale: 1;
        }
        100% {
            translate: 101% 0;
            scale: 1;
        }
    }

    @keyframes enterAnimation {
        0% {
            translate: 0% 0;
            scale: .9;
        }
        100% {
            translate: 0% 0;
            scale: 1;
        }
    }

`;

export const StyledImage = styled.figure`
    width: 100%;
    height: 30vh;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    margin: 0;
    
    @media only screen and (min-width: 769px) {
        max-height: 56vh;
        height: auto;
    }
    
    
    & img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-delay: .5s;
        ${({ visibility, selectedSubservice }) => {
            return css`
                animation-name: ${(visibility && selectedSubservice) ? 'zoom-in' : 'none' };
            `;
        }}
    }

    @keyframes zoom-in {
        to {
            scale: 1.5;
        }
    }
`;

export const StyledText = styled.figcaption`
    width: 100%;
    padding: 1rem 10%;
    margin: 0 0;
    z-index: 3;
    color: #000;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    box-sizing: border-box;
    background-color: #fff;
    height: fit-content;
    margin-top: auto;
    
    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 3px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }
    
    @media only screen and (min-width: 769px) {
        height: 24vh;
        width: 101%;
        text-align: left;
        overflow-y: scroll;
        margin-top: 0;
        margin-left: -1px;
        padding: 1rem 0.5rem 0 0.5rem;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 1.2rem;
    }
`;