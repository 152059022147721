import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

export const CarteMenuNotificationBar = styled.div`
    width: 90vw;
    background-color: #707070;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 10px;
    position: fixed;
    top: -3rem;
    left: 5vw;
    z-index: 9;
    transition: all 300ms ease-in-out;

    & span {
        margin-left: 1rem;
    }


    ${({ notificationBar }) => {
        return css`
            top: ${notificationBar ? '6rem' : '-3rem'};
            opacity: ${notificationBar ? '1' : '0'};
        `;
    }}
`;

export const CarteMenuCounter = styled.div`
    display: flex;
    position: fixed;
    bottom: 7rem;
    z-index: 10;
    background-color: #304962;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    box-shadow: 4px 4px 10px 1px rgb(0 0 0 / 50%);
    line-height: 0;
    transition: right 300ms 500ms ease-in-out;
    right: 1rem;
    opacity: 1;
    animation-name: enter;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    &::before {
        content: "";
        position: absolute;
        top: 0.4rem; // border: 2px + offset: 1px
        right: 0.4rem; // border: 2px + offset: 1px
        bottom: 0.4rem; // border: 2px + offset: 1px
        left: 0.4rem; // border: 2px + offset: 1px
        border: 1px solid #fff;
        border-radius: 50%; // border—radius: 6px + offset: 1px
    }

    &:hover {
        
        cursor: pointer;
    }

    & svg {
        height: 100%;
        width: 100%;
        padding: .3rem;
        border-radius: 0;
        box-sizing: border-box;
        overflow: visible;
    }
    
    & span {
        position: absolute;
        top: 38%;
        left: 47%;
        font-size: .9rem;
        color: #707070;
    }

    @media only screen and (min-width: 769px) {
        height: 3rem;
        width: 3rem;

        &::before {
            top: 0rem; // border: 2px + offset: 1px
            right: 0rem; // border: 2px + offset: 1px
            bottom: 0rem; // border: 2px + offset: 1px
            left: 0rem; // border: 2px + offset: 1px
            transition: all 200ms ease;
        }

        &:hover::before {
            content: "";
            position: absolute;
            top: 0.4rem; // border: 2px + offset: 1px
            right: 0.4rem; // border: 2px + offset: 1px
            bottom: 0.4rem; // border: 2px + offset: 1px
            left: 0.4rem; // border: 2px + offset: 1px
            border: 1px solid #fff;
            border-radius: 50%; // border—radius: 6px + offset: 1px
        }

        & span {
            font-size: 1rem;
        }
    }

    @keyframes enter {
        from {
            right: -10rem;
        }

        to {
            right: 1rem;
        }
    }
`;

export const CarteMenuCart = styled.aside`
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: fit-content;
    max-width: 100vw;
    min-width: 30vw;
    top: 0;
    transition: all 500ms ease-in-out;
    background-color: #fff;
    z-index: 11;
    padding: 1rem 1rem;
    box-sizing: border-box;
    color: #707070;

    & h3 {
        font-family: 'Kross Neue Grotesk', sans-serif;
        font-weight: 900;
    }
    
    & ul {
        font-family: 'Kross Neue Grotesk', sans-serif;
        font-weight: 350;
        list-style: disc;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    ${({ showCart }) => {
        return css`
            right: ${showCart ? '0' : '-100vh'};
        `;
    }}

    @media only screen and (max-width: 768px) {
        min-width: 50vw;
    }

    @media only screen and (max-width: 425px) {
        min-width: 100vw;
    }
    

`;

export const CloseCartButton = styled.span`
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 10;
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    line-height: 2rem;
    transform-origin: center center;
    background: #fff;
    height: 2rem;
    transform: rotateZ(90deg);

    &::before, &::after {
        content: '';
        display: block;
        width: 2rem;
        height: 2px;
        left: 0;
        position: absolute;
        background: #707070;
        transition: transform .4s cubic-bezier(.77,0,.175,1),left .4s cubic-bezier(.77,0,.175,1),top .4s cubic-bezier(.77,0,.175,1),bottom .4s cubic-bezier(.77,0,.175,1);
    }

    &::before {
        transform: rotateZ(45deg);
        top: calc(50% - 1px);
    }

    &::after {
        transform: rotateZ(-45deg);
        bottom: calc(50% - 1px);
    }

    &:hover {
        cursor: pointer;
    }
`;

export const CartButton = styled(Link)`
    padding: 1rem;
    margin-top: 1rem;
        text-align: center;
    color: #fff;
    border-radius: 5px;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    transition: background-color .3s ease-in-out;
    margin-top: auto;


    ${({ disabled }) => {
        return css`
            background-color: ${disabled ? '#c4c4c4' : '#304962'};
            &:hover {
                background-color: ${disabled ? 'none' : '#36597b'};
                cursor: ${disabled ? 'auto' : 'pointer'};
            }
        `;
    }}
`;