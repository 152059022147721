import { ArticleContainer, ArticleContent, ArticleTitle, ArticleText, ArticleImage } from "./AboutUsArticle.styles";
import { useRef } from "react";
import useOnScreen from "../../Hooks/useOnScreen";


const AboutUsArticle = ({ title, text, imageURL, altText }) => {

    const titleRef = useRef();
    const imageRef = useRef();
    const articleRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);
    const imageIsVisible = useOnScreen(imageRef);
    const articleIsVisible = useOnScreen(articleRef);

    return(
        <ArticleContainer ref={articleRef} visibility={articleIsVisible ? 1 : 0}>
            <ArticleContent>
                <ArticleTitle ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{title}</ArticleTitle>
                <ArticleText>{text}</ArticleText>
            </ArticleContent>
            <ArticleImage ref={imageRef} visibility={imageIsVisible ? 1 : 0}>
                <img src={imageURL} alt={altText}/>
            </ArticleImage>
        </ArticleContainer>
    );
}

export { AboutUsArticle };