import { StyledLogoContainer } from "./FooterLogo.styles";
import { MainContext } from "../../Context";
import { useContext } from "react";

const FooterLogo = () => {
    
    const {
        language
    } = useContext(MainContext);

    return(
        <StyledLogoContainer to={'/'+language+'/'} onClick={() => window.scrollTo(0, 0)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 624.65 209.17">
                <g id="Capa_2" data-name="Capa 2">
                    <g id="Layer_1" data-name="Layer 1">
                        <polygon fill='#E3E3E3' points="74.83 28.6 98.67 52.44 93.89 57.22 87.03 64.08 92.94 69.99 104.56 58.35 104.58 58.33 115.84 69.59 121.76 63.7 86.73 28.68 104.31 11.56 122.4 28.71 109.34 41.77 115.25 47.68 134.36 28.54 104.23 0 74.83 28.6"/>
                        <polygon fill='#E3E3E3' points="68.16 82.95 28.68 122.43 11.56 104.85 28.71 86.76 41.82 99.88 47.73 93.97 28.54 74.8 0 104.93 28.6 134.34 52.44 110.5 63.7 121.76 69.59 115.84 58.33 104.58 74.05 88.86 68.16 82.95"/>
                        <polygon fill='#E3E3E3' points="180.57 74.83 156.73 98.67 156.73 98.67 140.63 82.57 134.71 88.48 150.36 105.07 139.18 116.22 145.09 122.13 180.49 86.73 197.61 104.31 180.46 122.4 167.37 109.31 161.46 115.2 180.62 134.36 209.16 104.23 180.57 74.83"/>
                        <polygon fill='#E3E3E3' points="126.22 141 120.31 135.12 104.58 150.84 88.48 134.71 82.57 140.63 122.43 180.49 104.85 197.61 86.76 180.46 99.8 167.42 93.91 161.51 74.8 180.62 104.93 209.16 134.34 180.57 110.5 156.73 126.22 141"/>
                        <path fill='#868686' d="M162.72,60.72A13,13,0,0,0,164,59.27a15.38,15.38,0,0,0,21.58-21.91s-.11-.08-.14-.13.11-.08.14-.14A12.37,12.37,0,0,0,168,19.59a.6.6,0,0,1-.13.14c-.06-.06-.08-.11-.14-.14a15.38,15.38,0,0,0-21.9,21.59,12.09,12.09,0,0,0-1.45,1.23A13,13,0,0,0,141.81,57l-5.4,5.41a4.48,4.48,0,0,0-6.29,6.29L112.38,86.39l-7.8,7.79-7.82-7.82L79,68.62a4.48,4.48,0,0,0-6.29-6.29l-5.4-5.4a13,13,0,0,0-2.61-14.54,12.18,12.18,0,0,0-1.45-1.24,15.37,15.37,0,0,0-21.9-21.58,1.54,1.54,0,0,1-.14.13c0-.05-.08-.11-.13-.13A12.37,12.37,0,1,0,23.6,37.06c0,.06.11.09.13.14a.8.8,0,0,0-.13.13A15.38,15.38,0,0,0,45.18,59.24,12.87,12.87,0,0,0,61,63.3l5.4,5.4A4.48,4.48,0,0,0,72.65,75L90.39,92.73l7.82,7.82L87.27,111.49l-2,2h0l-8.22,8.23L49.91,148.85,35.45,163.31l-7.55,7.55,6.37,6.37,7.55-7.55,4,4,3.84-3.84,4,4-3.84,3.85,6,6,14.46-14.46-6-6L60.39,167l-4-4,3.84-3.84-4-4,30.91-30.91,5.24-5.24h0l4.68-4.68,4.17-4.16,3.33-3.34,3.33,3.34,4.17,4.16,4.68,4.68h0l5.24,5.24,30.91,30.91-4,4L152.8,163l-4,4-3.89-3.84-6,6,14.46,14.46,6-6-3.85-3.85,4-4,3.84,3.84,4-4,7.56,7.55,6.37-6.37-7.56-7.55-14.46-14.46-27.17-27.17-8.23-8.23h0l-2-2L111,100.55l7.82-7.82L136.51,75a4.48,4.48,0,0,0,6.29-6.29l5.41-5.4A12.89,12.89,0,0,0,162.72,60.72Zm10.89-35.54a4.5,4.5,0,1,1,0,6.37A4.47,4.47,0,0,1,173.61,25.18ZM169.36,43a7.51,7.51,0,1,1,0,10.62A7.51,7.51,0,0,1,169.36,43ZM151.57,25.18a7.51,7.51,0,1,1,0,10.62A7.52,7.52,0,0,1,151.57,25.18ZM150,48a5.06,5.06,0,1,1,0,7.15A5.08,5.08,0,0,1,150,48ZM29.22,25.18a4.5,4.5,0,1,1,0,6.37A4.47,4.47,0,0,1,29.22,25.18ZM39.81,53.57a7.51,7.51,0,1,1,0-10.62A7.51,7.51,0,0,1,39.81,53.57ZM47,25.18A7.51,7.51,0,1,1,47,35.8,7.53,7.53,0,0,1,47,25.18Zm12.21,30a5.06,5.06,0,1,1,0-7.15A5.06,5.06,0,0,1,59.19,55.15Z"/>
                        <path fill='#E3E3E3' d="M266.11,68.44v.15c0,28.7,17.34,52,38.83,52a47.67,47.67,0,0,0,35-14.58l3.68,3.68a56.4,56.4,0,0,1-38.67,15.35c-31.31,0-57.71-25.32-57.71-56.63,0-31.16,26.4-56.49,57.71-56.49a56.27,56.27,0,0,1,38.67,15.2L339.93,31a47.67,47.67,0,0,0-35-14.58C283.45,16.41,266.11,39.73,266.11,68.44Z"/>
                        <path fill='#E3E3E3' d="M405.31,16.56,456.42,124.3H432.94L417.13,91.15H369.86L354.05,124.3H349.6l41.75-87.63L403,12ZM415,86.7,393.5,41.27,372,86.7Z"/>
                        <path fill='#E3E3E3' d="M547.6,41.12v1.07c0,14-11.36,28.55-34.23,28.55H486.66V124.3h-18V12.57h44.66C536.24,12.57,547.6,27.15,547.6,41.12Zm-19.19.61c0-12.28-7.67-24.56-23-24.56H486.66V66.29h18.73C520.74,66.29,528.41,54,528.41,41.73Z"/>
                        <path fill='#E3E3E3' d="M564.48,12.57h60.17v4.6H582.44V66.29H615v4.45H582.44v49.11h42.21v4.45H564.48Z"/>
                        <path fill='#E3E3E3' d="M288.18,154.75v41.62h-6.56V167.93l-13.07,21.26-4.32,6.9-.79-1.29-14.59-23.56v25.13h-1.62V154.75l1.62,2.63,18.85,30.47,13.92-22.56Z"/>
                        <path fill='#E3E3E3' d="M313,157l18.68,39.38h-8.59l-5.77-12.11H300.08l-5.78,12.11h-1.63l15.26-32,4.27-9Zm3.53,25.64L308.72,166l-7.86,16.61Z"/>
                        <path fill='#E3E3E3' d="M362.41,155.53H364v41.12l-27.32-29.73v29.45h-1.63V155.31L362.41,185Z"/>
                        <path fill='#E3E3E3' d="M388.89,157l18.68,39.38H399l-5.78-12.11H375.93l-5.78,12.11h-1.62l15.26-32,4.26-9Zm3.54,25.64L384.57,166l-7.85,16.61Z"/>
                        <path fill='#E3E3E3' d="M450.49,176a20.65,20.65,0,0,1-11.83,18.74c-.06.06-.12.06-.17.11h-.06c-.05,0-.11.06-.17.11h-.05c-.06,0-.11.06-.17.06H438c-.05.06-.11.06-.16.11h-.06c-.06.06-.11.06-.17.11h-.06s-.11.06-.16.06h-.06c-.06.06-.11.06-.17.11h-.05c-.06,0-.12.06-.17.06h-.06a9.8,9.8,0,0,1-1.79.56.06.06,0,0,1-.06.06h-.17a0,0,0,0,1,0,.05h-.17l-.06.06h-.17c-.05,0-.05,0-.11,0h-.17l-.05.06h-.17a.16.16,0,0,0-.11.06h-.23a.22.22,0,0,1-.17.05h-.11a.17.17,0,0,0-.11.06h-.23c-.05,0-.11,0-.16,0h-.23c-.05,0-.11,0-.11.06H432c-.06,0-.12,0-.12.06h-.56a.19.19,0,0,0-.11.05h-1.4c-11.45,0-21.15-9.25-21.15-20.7s9.7-20.64,21.15-20.64A20.87,20.87,0,0,1,444,160.86l-1.41,1.4a17.22,17.22,0,0,0-12.73-5.33c-9.43,0-14.19,8.53-14.19,19V176c0,13.46,6.34,19,14.19,19,7.46,0,13.24-5.28,13.69-17.45H429.85V176Z"/>
                        <path fill='#E3E3E3' d="M456.11,155.53h22v1.69H462.67v17.95h11.9v1.62h-11.9v18H478.1v1.62h-22Z"/>
                        <path fill='#E3E3E3' d="M525.23,154.75v41.62h-6.57V167.93l-13.07,21.26-4.32,6.9-.78-1.29L485.9,171.24v25.13h-1.63V154.75l1.63,2.63,18.85,30.47,13.91-22.56Z"/>
                        <path fill='#E3E3E3' d="M533.08,155.53h22v1.69H539.65v17.95h11.89v1.62H539.65v18h15.43v1.62h-22Z"/>
                        <path fill='#E3E3E3' d="M588.57,155.53h1.63v41.12l-27.32-29.73v29.45h-1.63V155.31L588.57,185Z"/>
                        <path fill='#E3E3E3' d="M624.65,155.53v1.69H613.48v39.15H607V157.22H595.81v-1.69Z"/>
                    </g>
                </g>
            </svg>
        </StyledLogoContainer>
    );
}

export { FooterLogo };