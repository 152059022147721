
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import './styles.css';
import { 
    FormSectionContainer,
    FormSectionTitle,
    FormContainer,
    FormInput,
    FormTextTarea,
    AcceptInputContainer,
    AcceptInput,
    FormButton
} from "./Form.styles";
import { MainContext } from "../../Context";
import { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useOnScreen from "../../Hooks/useOnScreen";

const Form = () => {

    const { t } = useTranslation();

    const {
        language,
        contactForm
    } = useContext(MainContext);

    const [phone, setPhone] = useState("");

    const titleRef = useRef();
    const formRef = useRef();
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const messageInputRef = useRef();
    const acceptInputRef = useRef();

    const [isDisabled, setIsDisabled] = useState(true);
    const [checked, setChecked] = useState(false);

    const titleIsVisible = useOnScreen(titleRef);

    let [sendFlag, setSendFlag] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        let name = nameInputRef.current;
        let email = emailInputRef.current;
        let message = messageInputRef.current;
        let jsonInfo = {
            "name" : name.value,
            "email" : email.value,
            "phone" : "+" + phone,
            "message" : message.value,
        };

        if(!sendFlag && isNotEmpty(name) && isNotEmpty(email) && isNotEmpty(phone)){
            let xmlhttp = new XMLHttpRequest();
            setSendFlag(true);

            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState === 1) {
                    //notification.classList.add('right-in');
                    //notification.classList.add('loading');
                }
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    setTimeout(() => {
                        //notification.querySelector('.mail-icon').classList.add('fade-out');
                        setTimeout(() => {
                            //notification.classList.replace('loading', 'sent');
                            setTimeout(() => {
                                //notification.querySelector('.sent-check').classList.add('fill');
                                setTimeout(() => {
                                    //notification.classList.replace('right-in', 'right-out');
                                    formRef.current.reset();
                                    setChecked(false);
                                    setPhone('');
                                    setTimeout(() => {
                                        //notification.classList.remove('sent');
                                        //notification.classList.remove('right-out');
                                        //notification.querySelector('.sent-check').classList.remove('fill');
                                        //notification.querySelector('.mail-icon').classList.remove('fade-out');
                                        setSendFlag(false);
                                    }, 500);
                                }, 500);
                            }, 300);
                        }, 500);
                    },500)
                }
            }

            xmlhttp.open("POST", "/php/form.php", true);
            xmlhttp.send(JSON.stringify(jsonInfo));
        }

        return false;
    }

    const isNotEmpty = (caller) => {
        if (caller.value === '') {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        checkIfReady();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone]);

    useEffect(() => {
        checkIfReady();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const acceptChange = () => {
        setChecked(!checked);
    }

    const checkIfReady = () => {
        let name = nameInputRef.current;
        let email = emailInputRef.current;
        let message = messageInputRef.current;
        if ((name.checkValidity()) && (email.checkValidity()) && (phone > 1000000) && (message.checkValidity()) && checked) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    /* const [phoneNumber, setPhoneNumber] = useState(''); */

    /* const changeNumber = (e) => {
        let value = e.target.value;
        setPhoneNumber(value);
    } */

    /* const formatNumber = (e) => {
        let value = e.target.value;
        if (value.length >= 11 && value.length <= 13) {
            if(!isNaN(value)) {
                setPhoneNumber('+' + value.substring(0, (value.length-10)) + ' ' + value.substring((value.length-10), (value.length-7)) + '-' + value.substring((value.length-7), (value.length-4)) + '-' + value.substring((value.length-4), value.length));
            } else {
                let newValue = value.replaceAll(/[^\d]/g, '');
                setPhoneNumber(newValue);
            }
        } else {
            let newValue = value.replaceAll(/[^\d]/g, '');
            setPhoneNumber(newValue);
        }
    }

    const onFocusPhoneInput = (e) => {
        let value = e.target.value;
        let newValue = value.replaceAll(/[^\d]/g, '');
        setPhoneNumber(newValue);
    } */

    return(
        <FormSectionContainer>
            <FormSectionTitle ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{t('CONTACT_PAGE').TITLE} <span>{t('CONTACT_PAGE').TITLE_ITALIC}</span></FormSectionTitle>
            <FormContainer ref={formRef} onSubmit={sendEmail}>
                <FormInput ref={nameInputRef} title={t('CONTACT_PAGE').NAME_TITLE} onChange={checkIfReady} type="text" placeholder={t('CONTACT_PAGE').NAME + '*'} required/>
                <FormInput ref={emailInputRef} title={t('CONTACT_PAGE').EMAIL_TITLE} onChange={checkIfReady} type="email" placeholder={t('CONTACT_PAGE').EMAIL + '*'} required/>
               {/*  <FormInput ref={phoneInputRef} title={t('CONTACT_PAGE').PHONE_TITLE} onChange={changeNumber} onBlur={formatNumber} onFocus={onFocusPhoneInput} type="tel" pattern="\+[0-9]{1,3} [0-9]{3}-[0-9]{3}-[0-9]{4}" value={phoneNumber} placeholder={t('CONTACT_PAGE').PHONE + '*'} required/> */}
                <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                />
                <FormTextTarea ref={messageInputRef} title={t('CONTACT_PAGE').MESSAGE_TITLE} onChange={checkIfReady} placeholder={t('CONTACT_PAGE').MESSAGE} defaultValue={contactForm}/>
                <AcceptInputContainer>
                    {t('CONTACT_PAGE').ACCEPT_MESSAGE} <Link to={'/'+language+'/privacy-policy'}>{t('PAGES_NAME').PRIVACY_POLICY}</Link>
                    <input ref={acceptInputRef} title={t('CONTACT_PAGE').ACCEPT_TITLE} type="checkbox" checked={checked} required/>
                    <AcceptInput onClick={acceptChange} checked={checked}></AcceptInput>
                </AcceptInputContainer>
                <FormButton type="submit" value={t('CONTACT_PAGE').SUBMIT} disabled={isDisabled}/>
            </FormContainer>
        </FormSectionContainer>
    );
}

export { Form };