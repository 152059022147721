import { WhatsAppButtonContainer } from "./WhatsAppButton.styles";

const WhatsAppButton = ({text}) => {
    return (
        <WhatsAppButtonContainer href={`https://wa.me/+526241924306?text=${text}`} target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.542 71.542">
                <path id="Trazado_49" data-name="Trazado 49" d="M788.875,335.2h4.192a3.859,3.859,0,0,0,.54.114,34.033,34.033,0,0,1,15.607,4.921,35.3,35.3,0,0,1,16.971,24.581c.251,1.341.375,2.705.557,4.059v4.192c-.057.5-.115,1.005-.171,1.508a35.871,35.871,0,0,1-29.438,31.612c-1.346.238-2.71.373-4.066.555h-3.912c-1.015-.113-2.033-.2-3.044-.344a34.959,34.959,0,0,1-14.157-5.178,1.434,1.434,0,0,0-1.353-.186c-4.085,1.332-8.18,2.632-12.273,3.941-.225.072-.452.137-.77.234,1.429-4.253,2.8-8.372,4.2-12.482a1.446,1.446,0,0,0-.188-1.468,34.484,34.484,0,0,1-5.814-14.126c-.255-1.483-.378-2.989-.561-4.485v-3.773a4.869,4.869,0,0,0,.119-.6,34.08,34.08,0,0,1,4.877-15.48,35.293,35.293,0,0,1,24.613-17.036C786.152,335.505,787.519,335.383,788.875,335.2Zm13.763,55.973.046-.183a11.69,11.69,0,0,0,1.253-.081,10.1,10.1,0,0,0,1.7-.389c5.009-1.765,6.73-4.15,6.894-8.807a1.535,1.535,0,0,0-.948-1.612c-2.624-1.246-5.23-2.532-7.863-3.76-1.472-.686-2.264-.427-3.232.9-.659.9-1.316,1.8-2.005,2.683-1.1,1.408-1.87,1.6-3.507.931a28.289,28.289,0,0,1-13.706-12.128,1.5,1.5,0,0,1,.2-2.1c.7-.84,1.442-1.645,2.159-2.471a2.769,2.769,0,0,0,.509-3.263c-1.222-2.685-2.307-5.432-3.5-8.132a1.732,1.732,0,0,0-.934-.941c-1.878-.458-3.838-.557-5.4.725-3.48,2.852-4.585,6.671-3.9,11.014a20.915,20.915,0,0,0,3.917,8.625,55.538,55.538,0,0,0,9.437,10.614,37.282,37.282,0,0,0,13.744,7.186C799.179,390.487,800.923,390.782,802.638,391.173Z" transform="translate(-755.2 -335.2)" fill="#fff"/>
            </svg>
        </WhatsAppButtonContainer>
    );
}

export { WhatsAppButton };