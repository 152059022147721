import { SectionContainer, SectionTitle, SectionText } from "./IntroSection.styles";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useOnScreen from "../../Hooks/useOnScreen";

const IntroSection = () => {

    const titleRef = useRef();

    const { t } = useTranslation();

    const titleIsVisible = useOnScreen(titleRef);

    return(
        <SectionContainer>
            <SectionTitle ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{t('ABOUT_US_PAGE').TITLE} <span>{t('ABOUT_US_PAGE').TITLE_ITALIC}</span></SectionTitle>
            <SectionText>{t('ABOUT_US_PAGE').TEXT}</SectionText>
        </SectionContainer>
    );
}

export { IntroSection };