import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

const MainContext = createContext();



function MainProvider(props) {

    const scrollDirection = 'down';

    const [contactForm, setContactForm] = useState("");


    const { i18n } = useTranslation();

    const [language, setLanguage] = useState(i18n.language);

    const supportedLanguages = ["en", "es"];

    const [section, setSection] = useState('');
    const [selectedSubserviceGlobal, setSelectedSubservice] = useState('');
    const [serviceSelected, setServiceSelected] = useState('');
    
    const onChangeSection = (section) => {
        setSection(section);
    }

    const onChangeService = (service) => {
        setServiceSelected(service);
    }

    if (!supportedLanguages.includes(language)) {
        if(supportedLanguages.includes(navigator.language.substring(0, 2))){
            setLanguage(navigator.language.substring(0, 2));
            i18n.changeLanguage(navigator.language.substring(0, 2));
        } else {
            setLanguage('en');
            i18n.changeLanguage('en');
        }
    }

    const changeContactForm = (message) => {
        setContactForm(message);
    }

    const changeSelectedSubservice = (subservice) => {
        setSelectedSubservice(subservice);
    }

    const changeLanguage = (language) => {
        setLanguage(language);
        let pathname = window.location.pathname;
        let newPathname = '/' + language + pathname.substring(3);
        window.history.pushState(language, 'Cape', newPathname);
        i18n.changeLanguage(language);
    }

    return (
        <MainContext.Provider value={{
            language,
            changeLanguage,
            onChangeSection,
            section,
            scrollDirection,
            changeContactForm,
            contactForm,
            selectedSubserviceGlobal,
            changeSelectedSubservice,
            serviceSelected,
            onChangeService
        }}>
            {props.children}
        </MainContext.Provider>
    );
}

export { MainContext, MainProvider };