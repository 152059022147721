import styled, { css } from "styled-components/macro";

export const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 4rem 0;
`;

export const SectionTitle = styled.h2`
    color: #707070;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    margin: 1rem 0;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: center;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}
`;

export const SectionText = styled.p`
    color: #707070;
    margin: 0;
    padding: 0 10vw;
    text-align: center;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;

    @media only screen and (min-width: 1024px) {
        padding: 0 20vw;
    }

    @media only screen and (min-width: 1200px) {
        padding: 0 25vw;
    }
`;