import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLogoContainer = styled(Link)`
    height: fit-content;
    margin: 2rem;
    max-width: 100vw;
    box-sizing: border-box;
    position: relative;

    & svg {
        width: 100%;
    }

    @media only screen and (min-width: 425px) {
        height: 5rem;
        margin: 3rem;
        box-sizing: content-box;

        & svg {
            height: inherit;
            width: auto;
        }
    }
`;