import styled, { css } from 'styled-components/macro';

export const StyledNavbar = styled.nav`
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-color: inherit;
    transition: translate .5s ease-in-out;
    padding-right: .8rem;

    @media only screen and (min-width: 425px) {
        width: 80vw;
    }

    @media only screen and (min-width: 600px) {
        width: 50vw;
    }

    @media only screen and (min-width: 768px) {
        width: 30vw;
    }

    ${({ opened }) => {
        return css`
            translate: ${opened ? '0' : '-100vw'} 0;
        `;
    }}

    @media only screen and (min-width: 1200px) {
        position: initial;
        width: fit-content;
        height: fit-content;
        translate: 0 0;
        background-color: transparent;
    }

`;

export const StyledLinkList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width: 1200px) {
        width: initial;
        box-sizing: border-box;
        display: flex;
        gap: 1rem;
    }
`;

export const StyledListItem = styled.li`
    width: 100%;
    border-bottom: 1px solid #707070;
    box-sizing: border-box;
    padding: .8rem;
    background-color: inherit;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
    

    &:first-of-type {
        border-top: 1px solid #707070;
    }

    & a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        color: #707070;
    }

    @media only screen and (min-width: 1200px) {
        width: fit-content;
        border-bottom: none;
        position: relative;
        padding:  0;

        &::after {
            position: absolute;
            content: '';
            height: 2px;
            margin: 0;
            left: 0;
            right: 0;
            bottom: -2px;
            background: #707070;
            opacity: 1;
            transition: .5s;

            ${({ selected }) => {
                return css`
                    width: ${selected ? '100%' : '0'};
                `;
            }}
        }

        &:hover::after {
            ${({ selected }) => {
                return css`
                    width: ${selected ? '0' : '100%'};
                `;
            }}
        }

        &:first-of-type{
            border-top: none;
        }

    }
`;

