import { Fragment } from "react";
import { Hero } from "./Hero";
import { Form } from "./Form";
import { useContext, useEffect } from "react";
import { MainContext } from "../Context";
import { useTranslation } from "react-i18next";

const Contact = () => {

    const {
        onChangeSection,
        language,
        changeContactForm
    } = useContext(MainContext);

    const { t } = useTranslation();

    useEffect(() => {
        return function cleanup() {
            changeContactForm('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => { 
        onChangeSection(t('PAGES_NAME').CONTACT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[language]);
    
    return(
        <Fragment>
            <Hero />
            <Form />
        </Fragment>
    );
}

export { Contact };