import { useEffect, useRef, useState, useContext } from "react";
import { StyledImageContainer, StyledFigcaption } from "./GalleryImage.styles";
import { MainContext } from "../../../Context";
import useOnScreen from "../../../Hooks/useOnScreen";

const GalleryImage = ({ imageURL, altText, imageTitle, imageLink, serviceTitle }) => {

    const {
        scrollDirection,
        changeSelectedSubservice,
        onChangeService
    } = useContext(MainContext);

    const titleRef = useRef();
    const imageRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);
    const imageIsVisible = useOnScreen(imageRef);
    const [directionFrom, setDirectionFrom] = useState('0px 0px');

    useEffect(() => {
        if (imageRef.current.offsetTop === 0) {
            setDirectionFrom('0px -40px');
        } else if(imageRef.current.offsetLeft === 0) {
            setDirectionFrom('-40px 0px');
        } else if(window.innerWidth - imageRef.current.offsetLeft - imageRef.current.offsetWidth === 0) {
            setDirectionFrom('40px 0px');
        } else if(window.innerHeight - imageRef.current.offsetTop - imageRef.current.offsetHeight === 0) {
            setDirectionFrom('0px 40px');
        } else {
            if(scrollDirection === 'up') {
                setDirectionFrom('0px 40px');
            } else {
                setDirectionFrom('0px -40px');
            }
        }
    }, [scrollDirection]);

    const onClickLink = (imageTitle, serviceTitle) => {
        onChangeService(serviceTitle);
        changeSelectedSubservice(imageTitle);
    }

    return (
        <StyledImageContainer to={imageLink} ref={imageRef} visibility={imageIsVisible ? 1 : 0} directionfrom={directionFrom} onClick={() => onClickLink(imageTitle, serviceTitle)}>
            <img src={imageURL} alt={altText}/>
            <StyledFigcaption ref={titleRef}  visibility={titleIsVisible ? 1 : 0} >{imageTitle}</StyledFigcaption>
        </StyledImageContainer>
    );
}

export { GalleryImage };