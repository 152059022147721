import styled, { css } from "styled-components/macro";

export const PrivacyPolicyContainer = styled.section`
    margin-top: 2rem;
    padding: 10vw;

    @media only screen and (min-width: 1024px) {
        padding: 10vw 15vw;
    }
`;

export const PrivacyPolicyTitle = styled.h2`
    color: #707070;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    margin: 3rem 0 1rem;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: left;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}
`;

export const PrivacyDetails = styled.p`
    color: #707070;
    font-size: .8rem;
    width: 70%;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 3rem;

    @media only screen and (min-width: 600px) {
        width: 50%;
    }

    @media only screen and (min-width: 1024px) {
        width: 25%;
    }
`;

export const PrivacyPolicySubTitle = styled.h3`
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 800;
    font-style: normal;
    text-transform: uppercase;
`;

export const PrivacyPolicyParagraph = styled.p`
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
`;

export const PrivacyPolicyList = styled.ul`
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    list-style: disc;
`;

export const PrivacyPolicyListItem = styled.li`
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
`;