import styled from "styled-components";

export const StyledHero = styled.section`
    width: 100vw;
    height: 80vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
`;

export const StyledHeroBackground = styled.figure`
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    position: relative;

    & img {
        width: inherit;
        height: inherit;
        object-fit: cover;
        object-position: center;
    }
`;