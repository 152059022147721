import styled from "styled-components";

export const StyledFooterNav = styled.nav`
    margin-bottom: 2rem;
    @media only screen and (min-width: 769px) {
        width: 80%;
    }
`;

export const StyledFooterLinkList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: fit-content;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (min-width: 769px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    @media only screen and (min-width: 1200px) {
        width: initial;
        box-sizing: border-box;
    }
`;

export const StyledFooterListItem = styled.li`
    box-sizing: border-box;
    padding: .5rem;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 1rem;

    & a {
        text-decoration: none;
        display: flex;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
    }
`;