import styled, { css } from "styled-components";

export const PackageCardContainer = styled.article`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15rem;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    transition: all 400ms 300ms ease-in-out;
    translate: -50% -50%;

    ${({ isCurrentPackage, isPrevPackage, isNextPackage }) => {
        return css`
            z-index: ${(isCurrentPackage) ? '1' : (isPrevPackage || isNextPackage) ? '0' : '-1'};
            opacity: ${(isCurrentPackage || isPrevPackage || isNextPackage) ? '1' : '0'};
            translate: ${(isPrevPackage) ? '-125% -50%' : (isNextPackage) ? '25% -50%' : '-50% -50%'};
            scale: ${(isCurrentPackage) ? '1' : '.9'};
            height: ${(isCurrentPackage) ? '23rem' : '15rem'};
            cursor: ${(!isCurrentPackage) ? 'pointer' : 'auto'};
        `;
    }}

    @media only screen and (min-width: 1024px) {
        width: 20rem;

        ${({ isCurrentPackage}) => {
        return css`
            height: ${(isCurrentPackage) ? '25rem' : '20rem'};
        `;
        }}
    }
`;

export const PackageCardImage = styled.figure`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: all .5s ease-out;
    margin: 0;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        display: block;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(40, 40, 40, .75);
        transition: all 300ms 300ms ease-in-out;

        ${({ isCurrentPackage}) => {
        return css`
            opacity: ${(isCurrentPackage) ? '0' : '.7'};
        `;
        }}
    }

    @media only screen and (min-width: 769px) {
    }

    & figcaption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, .8);
        padding: 1rem;
        box-sizing: border-box;
        color: #707070;
        font-family: 'Kross Neue Grotesk', sans-serif;
        z-index: 10;

        ${({ isCurrentPackage}) => {
        return css`
            opacity: ${(isCurrentPackage) ? '1' : '0'};
            transition: ${(isCurrentPackage) ? 'all 300ms 300ms ease-in-out' : 'all 300ms ease-in-out'};
        `;
        }}
    }
`;

export const SelectButton = styled.button`
    padding: 1rem;
    margin-top: 1rem;
    background-color: #707070;
    color: #fff;
    min-width: 50%;
    border-radius: 5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: background-color .3s ease-in-out;
    border: none;
    
    &:hover {
        background-color: #5c5c5c;
    }

    ${({ isCurrentPackage}) => {
        return css`
            pointer-events: ${(!isCurrentPackage) && 'none'};
            cursor: ${(!isCurrentPackage) ? 'default' : 'pointer'};
        `;
    }}
`;

export const ServicesOnPackage = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 13px;

    @media only screen and (max-width: 1023px) {
        font-size: 11px;
    }
`;

export const PackageTitle = styled.h3`
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 3;
    font-size: 2rem;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    margin: 0;
    transition: all 300ms 300ms ease-in-out;
    cursor: pointer;

    ${({ isCurrentPackage}) => {
        return css`
            opacity: ${(isCurrentPackage) ? '0' : '1'};
        `;
    }}

    @media only screen and (max-width: 1023px) {
        font-size: 1.5rem;
    }

    @media only screen and (max-width: 599px) {
        display: none;
    }
`;

export const ServiceOnPackage = styled.li`
    width: 100%;
    min-height: .5rem;
    text-align: left;
`;