import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { MainProvider } from './Context';
import { NotFound } from './NotFound';
import { LocalizedRoutes } from './LocalizedRoutes';

function App() {

  const supportedLanguages = ["en", "es"];

  const localLanguage = (supportedLanguages.includes(navigator.language.substring(0, 2))) ? navigator.language.substring(0, 2) : 'en';

  return (
    <MainProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path={'/'}>
              <Route path={''} element={<Navigate to={'/'+localLanguage+'/'} />}/>
              <Route path={'about-us'} element={<Navigate to={'/'+localLanguage+'/about-us'} />}/>
              <Route path={'services'} element={<Navigate to={'/'+localLanguage+'/services'} />}/>
              <Route path={'contact'} element={<Navigate to={'/'+localLanguage+'/contact'} />}/>
              <Route path={'privacy-policy'} element={<Navigate to={'/'+localLanguage+'/privacy-policy'} />}/>
            </Route>
            <Route path={`/:locale/*`} element={<LocalizedRoutes />} />
            <Route path="*" element={ <NotFound />}/>
          </Routes>
        </Layout>
      </Router>
    </MainProvider>
  );
}

export default App;
