import styled, { css } from "styled-components/macro";

export const FormSectionContainer = styled.section`
    width: 100vw;
    box-sizing: border-box;

    @media only screen and (min-width: 769px) {
        padding: 0 10vw;
    }

    @media only screen and (min-width: 1024px) {
        padding: 0 20vw;
    }

    @media only screen and (min-width: 1200px) {
        padding: 0 25vw;
    }
`;

export const FormSectionTitle = styled.h2`
    color: #707070;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    margin: 3rem 0;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: center;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}
`;

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    padding: 0 2rem 3rem;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    gap: 1rem;
`;

export const FormInput = styled.input`
    width: 100%;
    padding: .5rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;

    &::placeholder {
        text-transform: uppercase;
        color: #707070;
        font-family: 'Kross Neue Grotesk', sans-serif;
        font-weight: bold;
        font-style: normal;
    }

    &:invalid {
        ${({ submited }) => {
            return css`
                border-bottom: ${submited ? '1px solid red' : '1px solid #707070'};
            `;
        }}
    }
    
    &:focus-visible {
        outline: #000 auto 1px
    }
`;

export const FormTextTarea = styled.textarea`
    width: 100%;
    padding: .5rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #707070;
    height: 40vh;
    resize: none;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;

    &::placeholder {
        text-transform: uppercase;
        color: #707070;
        font-family: 'Kross Neue Grotesk', sans-serif;
        font-weight: bold;
        font-style: normal;
    }

    &:invalid {
        ${({ submited }) => {
            return css`
                border-bottom: ${submited ? '1px solid red' : '1px solid #707070'};
            `;
        }}
    }

    &:focus-visible {
        outline: #000 auto 1px
    }
`;

export const AcceptInputContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: .8rem;
    user-select: none;
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;

    & a {
        font-weight: bold;
        color: #707070;
        text-decoration: none;
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

`;

export const AcceptInput = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: #eee;

    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        rotate: z 45deg;
    }

    ${({ checked }) => {
        return css`
            &:hover {
                background-color: ${checked ? '#2196F3' : '#ccc'};
            }

            background-color: ${checked && '#2196F3'};

            &::after {
                display: block;
            }
        `;
    }}
`;

export const FormButton = styled.input`
    color: #fff;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal; 
    border: none;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    text-transform: uppercase;
    margin: 2rem 0;
    transition: all .3s ease-in-out;

    ${({ disabled }) => {
        return css`
            background-color: ${disabled ? '#c4c4c4' : '#707070'};
            &:hover {
                background-color: ${disabled ? 'none' : '#5c5c5c'};
                cursor: ${disabled ? 'auto' : 'pointer'};
            }
        `;
    }}
`;