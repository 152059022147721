import { useEffect, useRef } from "react";
import useOnScreen from "../../../Hooks/useOnScreen";
import useWindowSize from "../../../Hooks/useWindowSize";
import { StyledSubserviceContainer, StyledImage, StyledText } from "./SubserviceItem.styles";


const SubserviceItem = ({description, imageURL, imageAlt, lastSubservice, selectedSubservice, setHeight, currentServiceSelected }) => {

    const ref = useRef();
    const imageRef = useRef();

    const imageIsVisible = useOnScreen(imageRef);

    const windowSize = useWindowSize();

    const getOffset = (el) => {
        const rect = el.getBoundingClientRect();
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        };
      }

    useEffect(() => {
        if(selectedSubservice && windowSize.width < 769) {
            setHeight(ref.current.offsetHeight);
            setTimeout( () => {
                window.scrollTo({
                    top: getOffset(currentServiceSelected).top - (ref.current.offsetHeight + 5*16),
                    left: 0,
                    behavior: 'smooth'
                });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSubservice]);

    useEffect(() => {
        if(selectedSubservice) {
            setTimeout( () => {
                setHeight(ref.current.offsetHeight);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);
    
    return(
        <StyledSubserviceContainer ref={ref} lastSubservice={(lastSubservice) ? 1 : 0} selectedSubservice={(selectedSubservice) ? 1 : 0}>
            <StyledImage ref={imageRef} visibility={imageIsVisible ? 1 : 0} selectedSubservice={(selectedSubservice) ? 1 : 0}>
                <img src={imageURL} alt={imageAlt} />
            </StyledImage>
            <StyledText>{description}</StyledText>
        </StyledSubserviceContainer>
    );
}

export { SubserviceItem };