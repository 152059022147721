import styled from "styled-components";

export const LogoContainer = styled.figure`
    width: fit-content;
    height: 2.5rem;
    margin: 0;
    justify-self: center;
    align-self: center;
    position: absolute;
    left: 0;
    transform-origin: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;

    & a {
        height: inherit;
    }

    & svg {
        height: inherit;
        margin-right: 10px;
    }
`;