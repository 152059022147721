import styled, { css } from 'styled-components/macro';

export const StyledHeader = styled.header`
    background-color: #fff;
    width: 100vw;
    padding: 2rem;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    transition: all .2s ease-in-out;
    top: 0;
    z-index: 10;

    @media only screen and (min-width: 1200px) {
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const ShadowBackground = styled.span`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity .5s ease-in-out;

    ${({ opened }) => {
        return css`
            opacity: ${opened ? '1' : '0'};
        `;
    }}

    ${({ show }) => {
        return css`
            display: ${show ? 'initial' : 'none'};
        `;
    }}

    @media only screen and (min-width: 1200px) {
        display: none;
    }
`;