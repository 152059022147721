import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyledHero, StyledHeroBackground, StyledHeroText } from "./Hero.styles";

const Hero = () => {

    const { t } = useTranslation();

    const ref = useRef();
    const textRef = useRef();

    useEffect(() => {

        document.querySelector('html').style.overflowY = 'hidden';
        let header = document.querySelector('header');
        header.style.backgroundColor = 'transparent';
        header.style.color = '#fff';
        function shuffle(array) {
            let currentIndex = array.length,  randomIndex;
          
            // While there remain elements to shuffle.
            while (currentIndex !== 0) {
          
              // Pick a remaining element.
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
          
            return array;
        }

        let squares = ref.current.querySelectorAll('path');
        let squareIndexes = Array.from(Array(squares.length).keys());
        let squareIndexesRandom = shuffle(squareIndexes);

        ref.current.querySelector('svg').style.backgroundColor = 'transparent';
        setTimeout(() => {
            let counter = 0;
            const heroInterval = setInterval(() => {
                squares[squareIndexesRandom[counter]].style.fill = 'transparent'
                counter++;
                if(counter >= squareIndexesRandom.length) {
                    clearInterval(heroInterval);
                    setTimeout(() => {
                        document.querySelector('html').style.overflowY = 'scroll';
                        header.style.backgroundColor = '#fff';
                        textRef.current.style.opacity = 1;
                    }, 87);
                }
            }, 1);
        }, 125);
    },[]);



    return (
        <StyledHero>
            <StyledHeroBackground ref={ref} windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            <svg width="1592" height="1592" viewBox="0 0 1592 1592" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1538.58 106.444L1591.61 159.477L1538.58 212.51L1485.54 159.477L1538.58 106.444Z" fill="white"/>
                <path d="M1538.58 212.51L1591.61 265.543L1538.58 318.576L1485.55 265.543L1538.58 212.51Z" fill="white"/>
                <path d="M1538.58 318.576L1591.61 371.609L1538.58 424.642L1485.55 371.609L1538.58 318.576Z" fill="white"/>
                <path d="M1538.58 424.642L1591.61 477.675L1538.58 530.708L1485.55 477.675L1538.58 424.642Z" fill="white"/>
                <path d="M1538.58 530.708L1591.61 583.741L1538.58 636.774L1485.54 583.741L1538.58 530.708Z" fill="white"/>
                <path d="M1538.58 0.378195L1591.61 53.4113L1538.58 106.444L1485.54 53.4111L1538.58 0.378195Z" fill="white"/>
                <path d="M1538.58 636.774L1591.61 689.807L1538.58 742.84L1485.54 689.807L1538.58 636.774Z" fill="white"/>
                <path d="M1538.58 742.84L1591.61 795.873L1538.58 848.906L1485.54 795.873L1538.58 742.84Z" fill="white"/>
                <path d="M1538.58 848.906L1591.61 901.939L1538.58 954.972L1485.54 901.939L1538.58 848.906Z" fill="white"/>
                <path d="M1538.58 954.972L1591.61 1008L1538.58 1061.04L1485.54 1008.01L1538.58 954.972Z" fill="white"/>
                <path d="M1538.58 1061.04L1591.61 1114.07L1538.58 1167.1L1485.54 1114.07L1538.58 1061.04Z" fill="white"/>
                <path d="M1538.58 1167.1L1591.61 1220.14L1538.58 1273.17L1485.54 1220.14L1538.58 1167.1Z" fill="white"/>
                <path d="M1538.58 1273.17L1591.61 1326.2L1538.58 1379.24L1485.54 1326.2L1538.58 1273.17Z" fill="white"/>
                <path d="M1538.58 1379.24L1591.61 1432.27L1538.58 1485.3L1485.54 1432.27L1538.58 1379.24Z" fill="white"/>
                <path d="M1538.58 1485.3L1591.61 1538.34L1538.58 1591.37L1485.54 1538.33L1538.58 1485.3Z" fill="white"/>
                <path d="M1485.54 53.4111L1538.58 106.444L1485.54 159.477L1432.51 106.444L1485.54 53.4111Z" fill="white"/>
                <path d="M1485.54 159.477L1538.58 212.51L1485.55 265.543L1432.51 212.51L1485.54 159.477Z" fill="white"/>
                <path d="M1485.55 265.543L1538.58 318.576L1485.55 371.609L1432.51 318.576L1485.55 265.543Z" fill="white"/>
                <path d="M1485.55 371.609L1538.58 424.642L1485.55 477.675L1432.51 424.642L1485.55 371.609Z" fill="white"/>
                <path d="M1485.55 477.675L1538.58 530.708L1485.54 583.741L1432.51 530.708L1485.55 477.675Z" fill="white"/>
                <path d="M1485.54 583.741L1538.58 636.774L1485.54 689.807L1432.51 636.774L1485.54 583.741Z" fill="white"/>
                <path d="M1432.51 106.444L1485.54 159.477L1432.51 212.51L1379.48 159.477L1432.51 106.444Z" fill="white"/>
                <path d="M1432.51 212.51L1485.55 265.543L1432.51 318.576L1379.48 265.543L1432.51 212.51Z" fill="white"/>
                <path d="M1432.51 318.576L1485.55 371.609L1432.51 424.642L1379.48 371.609L1432.51 318.576Z" fill="white"/>
                <path d="M1432.51 424.642L1485.55 477.675L1432.51 530.708L1379.48 477.675L1432.51 424.642Z" fill="white"/>
                <path d="M1432.51 530.708L1485.54 583.741L1432.51 636.774L1379.48 583.741L1432.51 530.708Z" fill="white"/>
                <path d="M1432.51 0.378163L1485.54 53.4111L1432.51 106.444L1379.48 53.4112L1432.51 0.378163Z" fill="white"/>
                <path d="M1432.51 636.774L1485.54 689.807L1432.51 742.84L1379.48 689.807L1432.51 636.774Z" fill="white"/>
                <path d="M1485.54 689.807L1538.58 742.84L1485.54 795.873L1432.51 742.84L1485.54 689.807Z" fill="white"/>
                <path d="M1432.51 742.84L1485.54 795.873L1432.51 848.906L1379.48 795.873L1432.51 742.84Z" fill="white"/>
                <path d="M1485.54 795.873L1538.58 848.906L1485.54 901.939L1432.51 848.906L1485.54 795.873Z" fill="white"/>
                <path d="M1432.51 848.906L1485.54 901.939L1432.51 954.972L1379.48 901.939L1432.51 848.906Z" fill="white"/>
                <path d="M1485.54 901.939L1538.58 954.972L1485.54 1008.01L1432.51 954.972L1485.54 901.939Z" fill="white"/>
                <path d="M1432.51 954.972L1485.54 1008.01L1432.51 1061.04L1379.48 1008L1432.51 954.972Z" fill="white"/>
                <path d="M1485.54 1008.01L1538.58 1061.04L1485.54 1114.07L1432.51 1061.04L1485.54 1008.01Z" fill="white"/>
                <path d="M1432.51 1061.04L1485.54 1114.07L1432.51 1167.1L1379.48 1114.07L1432.51 1061.04Z" fill="white"/>
                <path d="M1485.54 1114.07L1538.58 1167.1L1485.54 1220.14L1432.51 1167.1L1485.54 1114.07Z" fill="white"/>
                <path d="M1432.51 1167.1L1485.54 1220.14L1432.51 1273.17L1379.48 1220.14L1432.51 1167.1Z" fill="white"/>
                <path d="M1485.54 1220.14L1538.58 1273.17L1485.54 1326.2L1432.51 1273.17L1485.54 1220.14Z" fill="white"/>
                <path d="M1432.51 1273.17L1485.54 1326.2L1432.51 1379.24L1379.48 1326.2L1432.51 1273.17Z" fill="white"/>
                <path d="M1485.54 1326.2L1538.58 1379.24L1485.54 1432.27L1432.51 1379.24L1485.54 1326.2Z" fill="white"/>
                <path d="M1432.51 1379.24L1485.54 1432.27L1432.51 1485.3L1379.48 1432.27L1432.51 1379.24Z" fill="white"/>
                <path d="M1485.54 1432.27L1538.58 1485.3L1485.54 1538.33L1432.51 1485.3L1485.54 1432.27Z" fill="white"/>
                <path d="M1432.51 1485.3L1485.54 1538.33L1432.51 1591.37L1379.48 1538.34L1432.51 1485.3Z" fill="white"/>
                <path d="M1379.48 53.4112L1432.51 106.444L1379.48 159.477L1326.45 106.444L1379.48 53.4112Z" fill="white"/>
                <path d="M1379.48 159.477L1432.51 212.51L1379.48 265.543L1326.45 212.51L1379.48 159.477Z" fill="white"/>
                <path d="M1379.48 265.543L1432.51 318.576L1379.48 371.609L1326.45 318.576L1379.48 265.543Z" fill="white"/>
                <path d="M1379.48 371.609L1432.51 424.642L1379.48 477.675L1326.45 424.642L1379.48 371.609Z" fill="white"/>
                <path d="M1379.48 477.675L1432.51 530.708L1379.48 583.741L1326.45 530.708L1379.48 477.675Z" fill="white"/>
                <path d="M1379.48 583.741L1432.51 636.774L1379.48 689.807L1326.45 636.774L1379.48 583.741Z" fill="white"/>
                <path d="M1326.45 106.444L1379.48 159.477L1326.45 212.51L1273.41 159.477L1326.45 106.444Z" fill="white"/>
                <path d="M1326.45 212.51L1379.48 265.543L1326.45 318.576L1273.41 265.543L1326.45 212.51Z" fill="white"/>
                <path d="M1326.45 318.576L1379.48 371.609L1326.45 424.642L1273.41 371.609L1326.45 318.576Z" fill="white"/>
                <path d="M1326.45 424.642L1379.48 477.675L1326.45 530.708L1273.41 477.675L1326.45 424.642Z" fill="white"/>
                <path d="M1326.45 530.708L1379.48 583.741L1326.45 636.774L1273.41 583.741L1326.45 530.708Z" fill="white"/>
                <path d="M1326.45 0.377829L1379.48 53.4112L1326.45 106.444L1273.41 53.4108L1326.45 0.377829Z" fill="white"/>
                <path d="M1326.45 636.774L1379.48 689.807L1326.45 742.84L1273.41 689.807L1326.45 636.774Z" fill="white"/>
                <path d="M1379.48 689.807L1432.51 742.84L1379.48 795.873L1326.45 742.84L1379.48 689.807Z" fill="white"/>
                <path d="M1326.45 742.84L1379.48 795.873L1326.45 848.906L1273.41 795.873L1326.45 742.84Z" fill="white"/>
                <path d="M1379.48 795.873L1432.51 848.906L1379.48 901.939L1326.45 848.906L1379.48 795.873Z" fill="white"/>
                <path d="M1326.45 848.906L1379.48 901.939L1326.45 954.972L1273.41 901.939L1326.45 848.906Z" fill="white"/>
                <path d="M1379.48 901.939L1432.51 954.972L1379.48 1008L1326.45 954.972L1379.48 901.939Z" fill="white"/>
                <path d="M1326.45 954.972L1379.48 1008L1326.45 1061.04L1273.41 1008.01L1326.45 954.972Z" fill="white"/>
                <path d="M1379.48 1008L1432.51 1061.04L1379.48 1114.07L1326.45 1061.04L1379.48 1008Z" fill="white"/>
                <path d="M1326.45 1061.04L1379.48 1114.07L1326.45 1167.1L1273.41 1114.07L1326.45 1061.04Z" fill="white"/>
                <path d="M1379.48 1114.07L1432.51 1167.1L1379.48 1220.14L1326.45 1167.1L1379.48 1114.07Z" fill="white"/>
                <path d="M1326.45 1167.1L1379.48 1220.14L1326.45 1273.17L1273.41 1220.14L1326.45 1167.1Z" fill="white"/>
                <path d="M1379.48 1220.14L1432.51 1273.17L1379.48 1326.2L1326.45 1273.17L1379.48 1220.14Z" fill="white"/>
                <path d="M1326.45 1273.17L1379.48 1326.2L1326.45 1379.24L1273.41 1326.2L1326.45 1273.17Z" fill="white"/>
                <path d="M1379.48 1326.2L1432.51 1379.24L1379.48 1432.27L1326.45 1379.24L1379.48 1326.2Z" fill="white"/>
                <path d="M1326.45 1379.24L1379.48 1432.27L1326.44 1485.3L1273.41 1432.27L1326.45 1379.24Z" fill="white"/>
                <path d="M1379.48 1432.27L1432.51 1485.3L1379.48 1538.34L1326.44 1485.3L1379.48 1432.27Z" fill="white"/>
                <path d="M1326.44 1485.3L1379.48 1538.34L1326.44 1591.37L1273.41 1538.34L1326.44 1485.3Z" fill="white"/>
                <path d="M1273.41 53.4102L1326.45 106.444L1273.41 159.477L1220.38 106.443L1273.41 53.4102Z" fill="white"/>
                <path d="M1273.41 159.477L1326.45 212.51L1273.41 265.543L1220.38 212.509L1273.41 159.477Z" fill="white"/>
                <path d="M1273.41 265.543L1326.45 318.576L1273.41 371.609L1220.38 318.576L1273.41 265.543Z" fill="white"/>
                <path d="M1273.41 371.609L1326.45 424.642L1273.41 477.675L1220.38 424.641L1273.41 371.609Z" fill="white"/>
                <path d="M1273.41 477.675L1326.45 530.708L1273.41 583.741L1220.38 530.707L1273.41 477.675Z" fill="white"/>
                <path d="M1273.41 583.741L1326.45 636.774L1273.41 689.807L1220.38 636.773L1273.41 583.741Z" fill="white"/>
                <path d="M1220.38 106.443L1273.41 159.477L1220.38 212.509L1167.35 159.476L1220.38 106.443Z" fill="white"/>
                <path d="M1220.38 212.509L1273.41 265.543L1220.38 318.576L1167.35 265.542L1220.38 212.509Z" fill="white"/>
                <path d="M1220.38 318.576L1273.41 371.609L1220.38 424.641L1167.35 371.608L1220.38 318.576Z" fill="white"/>
                <path d="M1220.38 424.641L1273.41 477.675L1220.38 530.707L1167.35 477.674L1220.38 424.641Z" fill="white"/>
                <path d="M1220.38 530.707L1273.41 583.741L1220.38 636.773L1167.35 583.74L1220.38 530.707Z" fill="white"/>
                <path d="M1220.38 0.377181L1273.41 53.4102L1220.38 106.443L1167.35 53.4102L1220.38 0.377181Z" fill="white"/>
                <path d="M1220.38 636.773L1273.41 689.807L1220.38 742.839L1167.35 689.806L1220.38 636.773Z" fill="white"/>
                <path d="M1273.41 689.807L1326.45 742.84L1273.41 795.873L1220.38 742.839L1273.41 689.807Z" fill="white"/>
                <path d="M1220.38 742.839L1273.41 795.873L1220.38 848.905L1167.35 795.872L1220.38 742.839Z" fill="white"/>
                <path d="M1273.41 795.873L1326.45 848.906L1273.41 901.939L1220.38 848.905L1273.41 795.873Z" fill="white"/>
                <path d="M1220.38 848.905L1273.41 901.939L1220.38 954.971L1167.35 901.938L1220.38 848.905Z" fill="white"/>
                <path d="M1273.41 901.939L1326.45 954.972L1273.41 1008.01L1220.38 954.971L1273.41 901.939Z" fill="white"/>
                <path d="M1220.38 954.971L1273.41 1008.01L1220.38 1061.04L1167.35 1008L1220.38 954.971Z" fill="white"/>
                <path d="M1273.41 1008.01L1326.45 1061.04L1273.41 1114.07L1220.38 1061.04L1273.41 1008.01Z" fill="white"/>
                <path d="M1220.38 1061.04L1273.41 1114.07L1220.38 1167.1L1167.35 1114.07L1220.38 1061.04Z" fill="white"/>
                <path d="M1273.41 1114.07L1326.45 1167.1L1273.41 1220.14L1220.38 1167.1L1273.41 1114.07Z" fill="white"/>
                <path d="M1220.38 1167.1L1273.41 1220.14L1220.38 1273.17L1167.35 1220.14L1220.38 1167.1Z" fill="white"/>
                <path d="M1273.41 1220.14L1326.45 1273.17L1273.41 1326.2L1220.38 1273.17L1273.41 1220.14Z" fill="white"/>
                <path d="M1220.38 1273.17L1273.41 1326.2L1220.38 1379.24L1167.35 1326.2L1220.38 1273.17Z" fill="white"/>
                <path d="M1273.41 1326.2L1326.45 1379.24L1273.41 1432.27L1220.38 1379.24L1273.41 1326.2Z" fill="white"/>
                <path d="M1220.38 1379.24L1273.41 1432.27L1220.38 1485.3L1167.35 1432.27L1220.38 1379.24Z" fill="white"/>
                <path d="M1273.41 1432.27L1326.45 1485.3L1273.41 1538.34L1220.38 1485.3L1273.41 1432.27Z" fill="white"/>
                <path d="M1220.38 1485.3L1273.41 1538.34L1220.38 1591.37L1167.35 1538.34L1220.38 1485.3Z" fill="white"/>
                <path d="M1167.35 53.4102L1220.38 106.443L1167.35 159.476L1114.31 106.443L1167.35 53.4102Z" fill="white"/>
                <path d="M1167.35 159.476L1220.38 212.509L1167.35 265.542L1114.31 212.509L1167.35 159.476Z" fill="white"/>
                <path d="M1167.35 265.542L1220.38 318.576L1167.35 371.608L1114.31 318.575L1167.35 265.542Z" fill="white"/>
                <path d="M1167.35 371.608L1220.38 424.641L1167.35 477.674L1114.31 424.641L1167.35 371.608Z" fill="white"/>
                <path d="M1167.35 477.674L1220.38 530.707L1167.35 583.74L1114.31 530.707L1167.35 477.674Z" fill="white"/>
                <path d="M1167.35 583.74L1220.38 636.773L1167.35 689.806L1114.31 636.773L1167.35 583.74Z" fill="white"/>
                <path d="M1114.31 106.443L1167.35 159.476L1114.31 212.509L1061.28 159.476L1114.31 106.443Z" fill="white"/>
                <path d="M1114.31 212.509L1167.35 265.542L1114.31 318.575L1061.28 265.542L1114.31 212.509Z" fill="white"/>
                <path d="M1114.31 318.575L1167.35 371.608L1114.31 424.641L1061.28 371.608L1114.31 318.575Z" fill="white"/>
                <path d="M1114.31 424.641L1167.35 477.674L1114.31 530.707L1061.28 477.674L1114.31 424.641Z" fill="white"/>
                <path d="M1114.31 530.707L1167.35 583.74L1114.31 636.773L1061.28 583.74L1114.31 530.707Z" fill="white"/>
                <path d="M1114.31 0.377095L1167.35 53.4102L1114.31 106.443L1061.28 53.41L1114.31 0.377095Z" fill="white"/>
                <path d="M1114.31 636.773L1167.35 689.806L1114.31 742.839L1061.28 689.806L1114.31 636.773Z" fill="white"/>
                <path d="M1167.35 689.806L1220.38 742.839L1167.35 795.872L1114.31 742.839L1167.35 689.806Z" fill="white"/>
                <path d="M1114.31 742.839L1167.35 795.872L1114.31 848.906L1061.28 795.872L1114.31 742.839Z" fill="white"/>
                <path d="M1167.35 795.872L1220.38 848.905L1167.35 901.938L1114.31 848.906L1167.35 795.872Z" fill="white"/>
                <path d="M1114.31 848.906L1167.35 901.938L1114.31 954.971L1061.28 901.939L1114.31 848.906Z" fill="white"/>
                <path d="M1167.35 901.938L1220.38 954.971L1167.35 1008L1114.31 954.971L1167.35 901.938Z" fill="white"/>
                <path d="M1114.31 954.971L1167.35 1008L1114.31 1061.04L1061.28 1008L1114.31 954.971Z" fill="white"/>
                <path d="M1167.35 1008L1220.38 1061.04L1167.35 1114.07L1114.31 1061.04L1167.35 1008Z" fill="white"/>
                <path d="M1114.31 1061.04L1167.35 1114.07L1114.31 1167.1L1061.28 1114.07L1114.31 1061.04Z" fill="white"/>
                <path d="M1167.35 1114.07L1220.38 1167.1L1167.35 1220.14L1114.31 1167.1L1167.35 1114.07Z" fill="white"/>
                <path d="M1114.31 1167.1L1167.35 1220.14L1114.31 1273.17L1061.28 1220.14L1114.31 1167.1Z" fill="white"/>
                <path d="M1167.35 1220.14L1220.38 1273.17L1167.35 1326.2L1114.31 1273.17L1167.35 1220.14Z" fill="white"/>
                <path d="M1114.31 1273.17L1167.35 1326.2L1114.31 1379.24L1061.28 1326.2L1114.31 1273.17Z" fill="white"/>
                <path d="M1167.35 1326.2L1220.38 1379.24L1167.35 1432.27L1114.31 1379.24L1167.35 1326.2Z" fill="white"/>
                <path d="M1114.31 1379.24L1167.35 1432.27L1114.31 1485.3L1061.28 1432.27L1114.31 1379.24Z" fill="white"/>
                <path d="M1167.35 1432.27L1220.38 1485.3L1167.35 1538.34L1114.31 1485.3L1167.35 1432.27Z" fill="white"/>
                <path d="M1114.31 1485.3L1167.35 1538.34L1114.31 1591.37L1061.28 1538.34L1114.31 1485.3Z" fill="white"/>
                <path d="M1061.28 53.41L1114.31 106.443L1061.28 159.476L1008.25 106.443L1061.28 53.41Z" fill="white"/>
                <path d="M1061.28 159.476L1114.31 212.509L1061.28 265.542L1008.25 212.509L1061.28 159.476Z" fill="white"/>
                <path d="M1061.28 265.542L1114.31 318.575L1061.28 371.608L1008.25 318.575L1061.28 265.542Z" fill="white"/>
                <path d="M1061.28 371.608L1114.31 424.641L1061.28 477.674L1008.25 424.641L1061.28 371.608Z" fill="white"/>
                <path d="M1061.28 477.674L1114.31 530.707L1061.28 583.74L1008.25 530.707L1061.28 477.674Z" fill="white"/>
                <path d="M1061.28 583.74L1114.31 636.773L1061.28 689.806L1008.25 636.773L1061.28 583.74Z" fill="white"/>
                <path d="M1008.25 106.443L1061.28 159.476L1008.25 212.509L955.214 159.476L1008.25 106.443Z" fill="white"/>
                <path d="M1008.25 212.509L1061.28 265.542L1008.25 318.575L955.214 265.542L1008.25 212.509Z" fill="white"/>
                <path d="M1008.25 318.575L1061.28 371.608L1008.25 424.641L955.214 371.608L1008.25 318.575Z" fill="white"/>
                <path d="M1008.25 424.641L1061.28 477.674L1008.25 530.707L955.214 477.674L1008.25 424.641Z" fill="white"/>
                <path d="M1008.25 530.707L1061.28 583.74L1008.25 636.773L955.214 583.74L1008.25 530.707Z" fill="white"/>
                <path d="M1008.25 0.376858L1061.28 53.41L1008.25 106.443L955.213 53.4099L1008.25 0.376858Z" fill="white"/>
                <path d="M1008.25 636.773L1061.28 689.806L1008.25 742.839L955.214 689.806L1008.25 636.773Z" fill="white"/>
                <path d="M1061.28 689.806L1114.31 742.839L1061.28 795.872L1008.25 742.839L1061.28 689.806Z" fill="white"/>
                <path d="M1008.25 742.839L1061.28 795.872L1008.25 848.905L955.214 795.872L1008.25 742.839Z" fill="white"/>
                <path d="M1061.28 795.872L1114.31 848.906L1061.28 901.939L1008.25 848.905L1061.28 795.872Z" fill="white"/>
                <path d="M1008.25 848.905L1061.28 901.939L1008.25 954.971L955.214 901.938L1008.25 848.905Z" fill="white"/>
                <path d="M1061.28 901.939L1114.31 954.971L1061.28 1008L1008.25 954.971L1061.28 901.939Z" fill="white"/>
                <path d="M1008.25 954.971L1061.28 1008L1008.25 1061.04L955.214 1008L1008.25 954.971Z" fill="white"/>
                <path d="M1061.28 1008L1114.31 1061.04L1061.28 1114.07L1008.25 1061.04L1061.28 1008Z" fill="white"/>
                <path d="M1008.25 1061.04L1061.28 1114.07L1008.25 1167.1L955.214 1114.07L1008.25 1061.04Z" fill="white"/>
                <path d="M1061.28 1114.07L1114.31 1167.1L1061.28 1220.14L1008.25 1167.1L1061.28 1114.07Z" fill="white"/>
                <path d="M1008.25 1167.1L1061.28 1220.14L1008.25 1273.17L955.214 1220.14L1008.25 1167.1Z" fill="white"/>
                <path d="M1061.28 1220.14L1114.31 1273.17L1061.28 1326.2L1008.25 1273.17L1061.28 1220.14Z" fill="white"/>
                <path d="M1008.25 1273.17L1061.28 1326.2L1008.25 1379.24L955.214 1326.2L1008.25 1273.17Z" fill="white"/>
                <path d="M1061.28 1326.2L1114.31 1379.24L1061.28 1432.27L1008.25 1379.24L1061.28 1326.2Z" fill="white"/>
                <path d="M1008.25 1379.24L1061.28 1432.27L1008.25 1485.3L955.215 1432.27L1008.25 1379.24Z" fill="white"/>
                <path d="M1061.28 1432.27L1114.31 1485.3L1061.28 1538.34L1008.25 1485.3L1061.28 1432.27Z" fill="white"/>
                <path d="M1008.25 1485.3L1061.28 1538.34L1008.25 1591.37L955.214 1538.34L1008.25 1485.3Z" fill="white"/>
                <path d="M955.213 53.4099L1008.25 106.443L955.214 159.476L902.181 106.443L955.213 53.4099Z" fill="white"/>
                <path d="M955.214 159.476L1008.25 212.509L955.214 265.542L902.181 212.509L955.214 159.476Z" fill="white"/>
                <path d="M955.214 265.542L1008.25 318.575L955.214 371.608L902.181 318.575L955.214 265.542Z" fill="white"/>
                <path d="M955.214 371.608L1008.25 424.641L955.214 477.674L902.181 424.641L955.214 371.608Z" fill="white"/>
                <path d="M955.214 477.674L1008.25 530.707L955.214 583.74L902.181 530.707L955.214 477.674Z" fill="white"/>
                <path d="M955.214 583.74L1008.25 636.773L955.214 689.806L902.181 636.773L955.214 583.74Z" fill="white"/>
                <path d="M902.181 106.443L955.214 159.476L902.181 212.509L849.148 159.476L902.181 106.443Z" fill="white"/>
                <path d="M902.181 212.509L955.214 265.542L902.181 318.575L849.148 265.542L902.181 212.509Z" fill="white"/>
                <path d="M902.181 318.575L955.214 371.608L902.181 424.641L849.148 371.608L902.181 318.575Z" fill="white"/>
                <path d="M902.181 424.641L955.214 477.674L902.181 530.707L849.148 477.674L902.181 424.641Z" fill="white"/>
                <path d="M902.181 530.707L955.214 583.74L902.181 636.773L849.148 583.74L902.181 530.707Z" fill="white"/>
                <path d="M902.181 0.37675L955.213 53.4099L902.181 106.443L849.147 53.4097L902.181 0.37675Z" fill="white"/>
                <path d="M902.181 636.773L955.214 689.806L902.182 742.839L849.148 689.806L902.181 636.773Z" fill="white"/>
                <path d="M955.214 689.806L1008.25 742.839L955.214 795.872L902.182 742.839L955.214 689.806Z" fill="white"/>
                <path d="M902.182 742.839L955.214 795.872L902.182 848.905L849.148 795.872L902.182 742.839Z" fill="white"/>
                <path d="M955.214 795.872L1008.25 848.905L955.214 901.938L902.182 848.905L955.214 795.872Z" fill="white"/>
                <path d="M902.182 848.905L955.214 901.938L902.182 954.971L849.148 901.938L902.182 848.905Z" fill="white"/>
                <path d="M955.214 901.938L1008.25 954.971L955.214 1008L902.182 954.971L955.214 901.938Z" fill="white"/>
                <path d="M902.182 954.971L955.214 1008L902.182 1061.04L849.148 1008L902.182 954.971Z" fill="white"/>
                <path d="M955.214 1008L1008.25 1061.04L955.214 1114.07L902.182 1061.04L955.214 1008Z" fill="white"/>
                <path d="M902.182 1061.04L955.214 1114.07L902.181 1167.1L849.148 1114.07L902.182 1061.04Z" fill="white"/>
                <path d="M955.214 1114.07L1008.25 1167.1L955.214 1220.14L902.181 1167.1L955.214 1114.07Z" fill="white"/>
                <path d="M902.181 1167.1L955.214 1220.14L902.181 1273.17L849.148 1220.14L902.181 1167.1Z" fill="white"/>
                <path d="M955.214 1220.14L1008.25 1273.17L955.214 1326.2L902.181 1273.17L955.214 1220.14Z" fill="white"/>
                <path d="M902.181 1273.17L955.214 1326.2L902.181 1379.24L849.148 1326.2L902.181 1273.17Z" fill="white"/>
                <path d="M955.214 1326.2L1008.25 1379.24L955.215 1432.27L902.181 1379.24L955.214 1326.2Z" fill="white"/>
                <path d="M902.181 1379.24L955.215 1432.27L902.181 1485.3L849.148 1432.27L902.181 1379.24Z" fill="white"/>
                <path d="M955.215 1432.27L1008.25 1485.3L955.214 1538.34L902.181 1485.3L955.215 1432.27Z" fill="white"/>
                <path d="M902.181 1485.3L955.214 1538.34L902.181 1591.37L849.148 1538.34L902.181 1485.3Z" fill="white"/>
                <path d="M849.147 53.4097L902.181 106.443L849.148 159.476L796.115 106.443L849.147 53.4097Z" fill="white"/>
                <path d="M849.148 159.476L902.181 212.509L849.148 265.542L796.115 212.509L849.148 159.476Z" fill="white"/>
                <path d="M849.148 265.542L902.181 318.575L849.148 371.608L796.115 318.575L849.148 265.542Z" fill="white"/>
                <path d="M849.148 371.608L902.181 424.641L849.148 477.674L796.115 424.641L849.148 371.608Z" fill="white"/>
                <path d="M849.148 477.674L902.181 530.707L849.148 583.74L796.115 530.707L849.148 477.674Z" fill="white"/>
                <path d="M849.148 583.74L902.181 636.773L849.148 689.806L796.115 636.773L849.148 583.74Z" fill="white"/>
                <path d="M796.115 106.443L849.148 159.476L796.115 212.509L743.081 159.476L796.115 106.443Z" fill="white"/>
                <path d="M796.115 212.509L849.148 265.542L796.115 318.575L743.082 265.542L796.115 212.509Z" fill="white"/>
                <path d="M796.115 318.575L849.148 371.608L796.115 424.641L743.082 371.608L796.115 318.575Z" fill="white"/>
                <path d="M796.115 424.641L849.148 477.674L796.115 530.707L743.081 477.674L796.115 424.641Z" fill="white"/>
                <path d="M796.115 530.707L849.148 583.74L796.115 636.773L743.081 583.74L796.115 530.707Z" fill="white"/>
                <path d="M796.114 0.37703L849.147 53.4097L796.115 106.443L743.081 53.4101L796.114 0.37703Z" fill="white"/>
                <path d="M796.115 636.773L849.148 689.806L796.114 742.839L743.081 689.806L796.115 636.773Z" fill="white"/>
                <path d="M849.148 689.806L902.18 742.839L849.147 795.872L796.114 742.839L849.148 689.806Z" fill="white"/>
                <path d="M796.114 742.839L849.147 795.872L796.114 848.905L743.081 795.872L796.114 742.839Z" fill="white"/>
                <path d="M849.147 795.872L902.181 848.905L849.147 901.938L796.114 848.905L849.147 795.872Z" fill="white"/>
                <path d="M796.114 848.905L849.147 901.938L796.114 954.971L743.081 901.938L796.114 848.905Z" fill="white"/>
                <path d="M849.147 901.938L902.182 954.971L849.148 1008L796.114 954.971L849.147 901.938Z" fill="white"/>
                <path d="M796.114 954.971L849.148 1008L796.115 1061.04L743.081 1008L796.114 954.971Z" fill="white"/>
                <path d="M849.148 1008L902.181 1061.04L849.148 1114.07L796.115 1061.04L849.148 1008Z" fill="white"/>
                <path d="M796.115 1061.04L849.148 1114.07L796.114 1167.1L743.081 1114.07L796.115 1061.04Z" fill="white"/>
                <path d="M849.148 1114.07L902.181 1167.1L849.148 1220.14L796.114 1167.1L849.148 1114.07Z" fill="white"/>
                <path d="M796.114 1167.1L849.148 1220.14L796.114 1273.17L743.081 1220.14L796.114 1167.1Z" fill="white"/>
                <path d="M849.148 1220.14L902.181 1273.17L849.148 1326.2L796.114 1273.17L849.148 1220.14Z" fill="white"/>
                <path d="M796.114 1273.17L849.148 1326.2L796.115 1379.24L743.081 1326.2L796.114 1273.17Z" fill="white"/>
                <path d="M849.148 1326.2L902.181 1379.24L849.148 1432.27L796.115 1379.24L849.148 1326.2Z" fill="white"/>
                <path d="M796.115 1379.24L849.148 1432.27L796.115 1485.3L743.082 1432.27L796.115 1379.24Z" fill="white"/>
                <path d="M849.148 1432.27L902.181 1485.3L849.148 1538.34L796.115 1485.3L849.148 1432.27Z" fill="white"/>
                <path d="M796.115 1485.3L849.148 1538.34L796.115 1591.37L743.082 1538.33L796.115 1485.3Z" fill="white"/>
                <path d="M743.081 53.4101L796.115 106.443L743.081 159.476L690.049 106.443L743.081 53.4101Z" fill="white"/>
                <path d="M743.081 159.476L796.115 212.509L743.082 265.542L690.049 212.509L743.081 159.476Z" fill="white"/>
                <path d="M743.082 265.542L796.115 318.575L743.082 371.608L690.049 318.575L743.082 265.542Z" fill="white"/>
                <path d="M743.082 371.608L796.115 424.641L743.081 477.674L690.049 424.641L743.082 371.608Z" fill="white"/>
                <path d="M743.081 477.674L796.115 530.707L743.081 583.74L690.049 530.707L743.081 477.674Z" fill="white"/>
                <path d="M743.081 583.74L796.115 636.773L743.081 689.806L690.049 636.773L743.081 583.74Z" fill="white"/>
                <path d="M690.049 106.443L743.081 159.476L690.049 212.509L637.015 159.476L690.049 106.443Z" fill="white"/>
                <path d="M690.049 212.509L743.082 265.542L690.049 318.575L637.016 265.542L690.049 212.509Z" fill="white"/>
                <path d="M690.049 318.575L743.082 371.608L690.049 424.641L637.016 371.608L690.049 318.575Z" fill="white"/>
                <path d="M690.049 424.641L743.081 477.674L690.049 530.707L637.016 477.674L690.049 424.641Z" fill="white"/>
                <path d="M690.049 530.707L743.081 583.74L690.049 636.773L637.015 583.74L690.049 530.707Z" fill="white"/>
                <path d="M690.048 0.377095L743.081 53.4101L690.049 106.443L637.015 53.4101L690.048 0.377095Z" fill="white"/>
                <path d="M690.049 636.773L743.081 689.806L690.049 742.839L637.015 689.806L690.049 636.773Z" fill="white"/>
                <path d="M743.081 689.806L796.114 742.839L743.081 795.872L690.049 742.839L743.081 689.806Z" fill="white"/>
                <path d="M690.049 742.839L743.081 795.872L690.049 848.905L637.015 795.872L690.049 742.839Z" fill="white"/>
                <path d="M743.081 795.872L796.114 848.905L743.081 901.938L690.049 848.905L743.081 795.872Z" fill="white"/>
                <path d="M690.049 848.905L743.081 901.938L690.048 954.971L637.015 901.938L690.049 848.905Z" fill="white"/>
                <path d="M743.081 901.938L796.114 954.971L743.081 1008L690.048 954.971L743.081 901.938Z" fill="white"/>
                <path d="M690.048 954.971L743.081 1008L690.049 1061.04L637.015 1008L690.048 954.971Z" fill="white"/>
                <path d="M743.081 1008L796.115 1061.04L743.081 1114.07L690.049 1061.04L743.081 1008Z" fill="white"/>
                <path d="M690.049 1061.04L743.081 1114.07L690.049 1167.1L637.015 1114.07L690.049 1061.04Z" fill="white"/>
                <path d="M743.081 1114.07L796.114 1167.1L743.081 1220.14L690.049 1167.1L743.081 1114.07Z" fill="white"/>
                <path d="M690.049 1167.1L743.081 1220.14L690.049 1273.17L637.016 1220.14L690.049 1167.1Z" fill="white"/>
                <path d="M743.081 1220.14L796.114 1273.17L743.081 1326.2L690.049 1273.17L743.081 1220.14Z" fill="white"/>
                <path d="M690.049 1273.17L743.081 1326.2L690.049 1379.24L637.016 1326.2L690.049 1273.17Z" fill="white"/>
                <path d="M743.081 1326.2L796.115 1379.24L743.082 1432.27L690.049 1379.24L743.081 1326.2Z" fill="white"/>
                <path d="M690.049 1379.24L743.082 1432.27L690.049 1485.3L637.015 1432.27L690.049 1379.24Z" fill="white"/>
                <path d="M743.082 1432.27L796.115 1485.3L743.082 1538.33L690.049 1485.3L743.082 1432.27Z" fill="white"/>
                <path d="M690.049 1485.3L743.082 1538.33L690.048 1591.37L637.015 1538.33L690.049 1485.3Z" fill="white"/>
                <path d="M637.015 53.4101L690.049 106.443L637.015 159.476L583.982 106.443L637.015 53.4101Z" fill="white"/>
                <path d="M637.015 159.476L690.049 212.509L637.016 265.542L583.982 212.509L637.015 159.476Z" fill="white"/>
                <path d="M637.016 265.542L690.049 318.575L637.016 371.608L583.982 318.575L637.016 265.542Z" fill="white"/>
                <path d="M637.016 371.608L690.049 424.641L637.016 477.674L583.982 424.641L637.016 371.608Z" fill="white"/>
                <path d="M637.016 477.674L690.049 530.707L637.015 583.74L583.982 530.707L637.016 477.674Z" fill="white"/>
                <path d="M637.015 583.74L690.049 636.773L637.015 689.806L583.982 636.772L637.015 583.74Z" fill="white"/>
                <path d="M583.982 106.443L637.015 159.476L583.982 212.509L530.949 159.476L583.982 106.443Z" fill="white"/>
                <path d="M583.982 212.509L637.016 265.542L583.982 318.575L530.949 265.542L583.982 212.509Z" fill="white"/>
                <path d="M583.982 318.575L637.016 371.608L583.982 424.641L530.949 371.608L583.982 318.575Z" fill="white"/>
                <path d="M583.982 424.641L637.016 477.674L583.982 530.707L530.949 477.674L583.982 424.641Z" fill="white"/>
                <path d="M583.982 530.707L637.015 583.74L583.982 636.772L530.949 583.74L583.982 530.707Z" fill="white"/>
                <path d="M583.982 0.37703L637.015 53.4101L583.982 106.443L530.949 53.41L583.982 0.37703Z" fill="white"/>
                <path d="M583.982 636.772L637.015 689.806L583.982 742.838L530.949 689.805L583.982 636.772Z" fill="white"/>
                <path d="M637.015 689.806L690.049 742.839L637.015 795.872L583.982 742.838L637.015 689.806Z" fill="white"/>
                <path d="M583.982 742.838L637.015 795.872L583.982 848.905L530.949 795.871L583.982 742.838Z" fill="white"/>
                <path d="M637.015 795.872L690.049 848.905L637.015 901.938L583.982 848.905L637.015 795.872Z" fill="white"/>
                <path d="M583.982 848.905L637.015 901.938L583.982 954.971L530.949 901.938L583.982 848.905Z" fill="white"/>
                <path d="M637.015 901.938L690.048 954.971L637.015 1008L583.982 954.971L637.015 901.938Z" fill="white"/>
                <path d="M583.982 954.971L637.015 1008L583.982 1061.04L530.949 1008L583.982 954.971Z" fill="white"/>
                <path d="M637.015 1008L690.049 1061.04L637.015 1114.07L583.982 1061.04L637.015 1008Z" fill="white"/>
                <path d="M583.982 1061.04L637.015 1114.07L583.982 1167.1L530.949 1114.07L583.982 1061.04Z" fill="white"/>
                <path d="M637.015 1114.07L690.049 1167.1L637.016 1220.14L583.982 1167.1L637.015 1114.07Z" fill="white"/>
                <path d="M583.982 1167.1L637.016 1220.14L583.982 1273.17L530.949 1220.14L583.982 1167.1Z" fill="white"/>
                <path d="M637.016 1220.14L690.049 1273.17L637.016 1326.2L583.982 1273.17L637.016 1220.14Z" fill="white"/>
                <path d="M583.982 1273.17L637.016 1326.2L583.982 1379.24L530.949 1326.2L583.982 1273.17Z" fill="white"/>
                <path d="M637.016 1326.2L690.049 1379.24L637.015 1432.27L583.982 1379.24L637.016 1326.2Z" fill="white"/>
                <path d="M583.982 1379.24L637.015 1432.27L583.982 1485.3L530.949 1432.27L583.982 1379.24Z" fill="white"/>
                <path d="M637.015 1432.27L690.049 1485.3L637.015 1538.33L583.982 1485.3L637.015 1432.27Z" fill="white"/>
                <path d="M583.982 1485.3L637.015 1538.33L583.982 1591.37L530.949 1538.33L583.982 1485.3Z" fill="white"/>
                <path d="M530.949 53.41L583.982 106.443L530.949 159.476L477.916 106.443L530.949 53.41Z" fill="white"/>
                <path d="M530.949 159.476L583.982 212.509L530.949 265.542L477.916 212.509L530.949 159.476Z" fill="white"/>
                <path d="M530.949 265.542L583.982 318.575L530.949 371.608L477.916 318.575L530.949 265.542Z" fill="white"/>
                <path d="M530.949 371.608L583.982 424.641L530.949 477.674L477.916 424.641L530.949 371.608Z" fill="white"/>
                <path d="M530.949 477.674L583.982 530.707L530.949 583.74L477.916 530.707L530.949 477.674Z" fill="white"/>
                <path d="M530.949 583.74L583.982 636.772L530.949 689.805L477.916 636.773L530.949 583.74Z" fill="white"/>
                <path d="M477.916 106.443L530.949 159.476L477.916 212.509L424.883 159.476L477.916 106.443Z" fill="white"/>
                <path d="M477.916 212.509L530.949 265.542L477.916 318.575L424.883 265.542L477.916 212.509Z" fill="white"/>
                <path d="M477.916 318.575L530.949 371.608L477.916 424.641L424.883 371.608L477.916 318.575Z" fill="white"/>
                <path d="M477.916 424.641L530.949 477.674L477.916 530.707L424.883 477.674L477.916 424.641Z" fill="white"/>
                <path d="M477.916 530.707L530.949 583.74L477.916 636.773L424.883 583.74L477.916 530.707Z" fill="white"/>
                <path d="M477.916 0.376793L530.949 53.41L477.916 106.443L424.883 53.4098L477.916 0.376793Z" fill="white"/>
                <path d="M477.916 636.773L530.949 689.805L477.916 742.839L424.883 689.806L477.916 636.773Z" fill="white"/>
                <path d="M530.949 689.805L583.982 742.838L530.949 795.871L477.916 742.839L530.949 689.805Z" fill="white"/>
                <path d="M477.916 742.839L530.949 795.871L477.916 848.905L424.883 795.872L477.916 742.839Z" fill="white"/>
                <path d="M530.949 795.871L583.982 848.905L530.949 901.938L477.916 848.905L530.949 795.871Z" fill="white"/>
                <path d="M477.916 848.905L530.949 901.938L477.916 954.971L424.883 901.938L477.916 848.905Z" fill="white"/>
                <path d="M530.949 901.938L583.982 954.971L530.949 1008L477.916 954.971L530.949 901.938Z" fill="white"/>
                <path d="M477.916 954.971L530.949 1008L477.916 1061.04L424.883 1008L477.916 954.971Z" fill="white"/>
                <path d="M530.949 1008L583.982 1061.04L530.949 1114.07L477.916 1061.04L530.949 1008Z" fill="white"/>
                <path d="M477.916 1061.04L530.949 1114.07L477.916 1167.1L424.883 1114.07L477.916 1061.04Z" fill="white"/>
                <path d="M530.949 1114.07L583.982 1167.1L530.949 1220.14L477.916 1167.1L530.949 1114.07Z" fill="white"/>
                <path d="M477.916 1167.1L530.949 1220.14L477.916 1273.17L424.883 1220.14L477.916 1167.1Z" fill="white"/>
                <path d="M530.949 1220.14L583.982 1273.17L530.949 1326.2L477.916 1273.17L530.949 1220.14Z" fill="white"/>
                <path d="M477.916 1273.17L530.949 1326.2L477.916 1379.24L424.883 1326.2L477.916 1273.17Z" fill="white"/>
                <path d="M530.949 1326.2L583.982 1379.24L530.949 1432.27L477.916 1379.24L530.949 1326.2Z" fill="white"/>
                <path d="M477.916 1379.24L530.949 1432.27L477.916 1485.3L424.883 1432.27L477.916 1379.24Z" fill="white"/>
                <path d="M530.949 1432.27L583.982 1485.3L530.949 1538.33L477.916 1485.3L530.949 1432.27Z" fill="white"/>
                <path d="M477.916 1485.3L530.949 1538.33L477.916 1591.37L424.883 1538.33L477.916 1485.3Z" fill="white"/>
                <path d="M424.883 53.4098L477.916 106.443L424.883 159.476L371.85 106.442L424.883 53.4098Z" fill="white"/>
                <path d="M424.883 159.476L477.916 212.509L424.883 265.542L371.85 212.508L424.883 159.476Z" fill="white"/>
                <path d="M424.883 265.542L477.916 318.575L424.883 371.608L371.851 318.574L424.883 265.542Z" fill="white"/>
                <path d="M424.883 371.608L477.916 424.641L424.883 477.674L371.851 424.64L424.883 371.608Z" fill="white"/>
                <path d="M424.883 477.674L477.916 530.707L424.883 583.74L371.851 530.706L424.883 477.674Z" fill="white"/>
                <path d="M424.883 583.74L477.916 636.773L424.883 689.806L371.85 636.772L424.883 583.74Z" fill="white"/>
                <path d="M371.85 106.442L424.883 159.476L371.85 212.508L318.817 159.475L371.85 106.442Z" fill="white"/>
                <path d="M371.85 212.508L424.883 265.542L371.851 318.574L318.817 265.541L371.85 212.508Z" fill="white"/>
                <path d="M371.851 318.574L424.883 371.608L371.851 424.64L318.817 371.607L371.851 318.574Z" fill="white"/>
                <path d="M371.851 424.64L424.883 477.674L371.851 530.706L318.817 477.673L371.851 424.64Z" fill="white"/>
                <path d="M371.851 530.706L424.883 583.74L371.85 636.772L318.817 583.739L371.851 530.706Z" fill="white"/>
                <path d="M371.85 0.37621L424.883 53.4098L371.85 106.442L318.817 53.4092L371.85 0.37621Z" fill="white"/>
                <path d="M371.85 636.772L424.883 689.806L371.85 742.838L318.817 689.805L371.85 636.772Z" fill="white"/>
                <path d="M424.883 689.806L477.916 742.839L424.883 795.872L371.85 742.838L424.883 689.806Z" fill="white"/>
                <path d="M371.85 742.838L424.883 795.872L371.85 848.904L318.817 795.871L371.85 742.838Z" fill="white"/>
                <path d="M424.883 795.872L477.916 848.905L424.883 901.938L371.85 848.904L424.883 795.872Z" fill="white"/>
                <path d="M371.85 848.904L424.883 901.938L371.85 954.97L318.817 901.937L371.85 848.904Z" fill="white"/>
                <path d="M424.883 901.938L477.916 954.971L424.883 1008L371.85 954.97L424.883 901.938Z" fill="white"/>
                <path d="M371.85 954.97L424.883 1008L371.851 1061.04L318.817 1008L371.85 954.97Z" fill="white"/>
                <path d="M424.883 1008L477.916 1061.04L424.883 1114.07L371.851 1061.04L424.883 1008Z" fill="white"/>
                <path d="M371.851 1061.04L424.883 1114.07L371.851 1167.1L318.817 1114.07L371.851 1061.04Z" fill="white"/>
                <path d="M424.883 1114.07L477.916 1167.1L424.883 1220.14L371.851 1167.1L424.883 1114.07Z" fill="white"/>
                <path d="M371.851 1167.1L424.883 1220.14L371.85 1273.17L318.817 1220.14L371.851 1167.1Z" fill="white"/>
                <path d="M424.883 1220.14L477.916 1273.17L424.884 1326.2L371.85 1273.17L424.883 1220.14Z" fill="white"/>
                <path d="M371.85 1273.17L424.884 1326.2L371.851 1379.24L318.817 1326.2L371.85 1273.17Z" fill="white"/>
                <path d="M424.884 1326.2L477.916 1379.24L424.884 1432.27L371.851 1379.24L424.884 1326.2Z" fill="white"/>
                <path d="M371.851 1379.24L424.884 1432.27L371.851 1485.3L318.818 1432.27L371.851 1379.24Z" fill="white"/>
                <path d="M424.884 1432.27L477.917 1485.3L424.884 1538.33L371.851 1485.3L424.884 1432.27Z" fill="white"/>
                <path d="M371.851 1485.3L424.884 1538.33L371.851 1591.37L318.818 1538.33L371.851 1485.3Z" fill="white"/>
                <path d="M318.817 53.4092L371.85 106.442L318.817 159.475L265.784 106.442L318.817 53.4092Z" fill="white"/>
                <path d="M318.817 159.475L371.85 212.508L318.817 265.541L265.784 212.508L318.817 159.475Z" fill="white"/>
                <path d="M318.817 265.541L371.851 318.574L318.817 371.607L265.785 318.574L318.817 265.541Z" fill="white"/>
                <path d="M318.817 371.607L371.851 424.64L318.817 477.673L265.785 424.64L318.817 371.607Z" fill="white"/>
                <path d="M318.817 477.673L371.851 530.706L318.817 583.739L265.784 530.706L318.817 477.673Z" fill="white"/>
                <path d="M318.817 583.739L371.85 636.772L318.817 689.805L265.784 636.772L318.817 583.739Z" fill="white"/>
                <path d="M265.784 106.442L318.817 159.475L265.784 212.508L212.751 159.475L265.784 106.442Z" fill="white"/>
                <path d="M265.784 212.508L318.817 265.541L265.785 318.574L212.751 265.541L265.784 212.508Z" fill="white"/>
                <path d="M265.785 318.574L318.817 371.607L265.785 424.64L212.751 371.607L265.785 318.574Z" fill="white"/>
                <path d="M265.785 424.64L318.817 477.673L265.784 530.706L212.751 477.673L265.785 424.64Z" fill="white"/>
                <path d="M265.784 530.706L318.817 583.739L265.784 636.772L212.751 583.739L265.784 530.706Z" fill="white"/>
                <path d="M265.784 0.376178L318.817 53.4092L265.784 106.442L212.751 53.4091L265.784 0.376178Z" fill="white"/>
                <path d="M265.784 636.772L318.817 689.805L265.785 742.838L212.751 689.805L265.784 636.772Z" fill="white"/>
                <path d="M318.817 689.805L371.85 742.838L318.817 795.871L265.785 742.838L318.817 689.805Z" fill="white"/>
                <path d="M265.785 742.838L318.817 795.871L265.785 848.904L212.751 795.871L265.785 742.838Z" fill="white"/>
                <path d="M318.817 795.871L371.85 848.904L318.817 901.937L265.785 848.904L318.817 795.871Z" fill="white"/>
                <path d="M265.785 848.904L318.817 901.937L265.784 954.97L212.751 901.937L265.785 848.904Z" fill="white"/>
                <path d="M318.817 901.937L371.85 954.97L318.817 1008L265.784 954.97L318.817 901.937Z" fill="white"/>
                <path d="M265.784 954.97L318.817 1008L265.784 1061.04L212.751 1008L265.784 954.97Z" fill="white"/>
                <path d="M318.817 1008L371.851 1061.04L318.817 1114.07L265.784 1061.04L318.817 1008Z" fill="white"/>
                <path d="M265.784 1061.04L318.817 1114.07L265.785 1167.1L212.751 1114.07L265.784 1061.04Z" fill="white"/>
                <path d="M318.817 1114.07L371.851 1167.1L318.817 1220.14L265.785 1167.1L318.817 1114.07Z" fill="white"/>
                <path d="M265.785 1167.1L318.817 1220.14L265.785 1273.17L212.751 1220.14L265.785 1167.1Z" fill="white"/>
                <path d="M318.817 1220.14L371.85 1273.17L318.817 1326.2L265.785 1273.17L318.817 1220.14Z" fill="white"/>
                <path d="M265.785 1273.17L318.817 1326.2L265.785 1379.24L212.751 1326.2L265.785 1273.17Z" fill="white"/>
                <path d="M318.817 1326.2L371.851 1379.24L318.818 1432.27L265.785 1379.24L318.817 1326.2Z" fill="white"/>
                <path d="M265.785 1379.24L318.818 1432.27L265.784 1485.3L212.751 1432.27L265.785 1379.24Z" fill="white"/>
                <path d="M318.818 1432.27L371.851 1485.3L318.818 1538.33L265.784 1485.3L318.818 1432.27Z" fill="white"/>
                <path d="M265.784 1485.3L318.818 1538.33L265.784 1591.37L212.751 1538.33L265.784 1485.3Z" fill="white"/>
                <path d="M212.751 53.4091L265.784 106.442L212.751 159.475L159.718 106.442L212.751 53.4091Z" fill="white"/>
                <path d="M212.751 159.475L265.784 212.508L212.751 265.541L159.718 212.508L212.751 159.475Z" fill="white"/>
                <path d="M212.751 265.541L265.785 318.574L212.751 371.607L159.718 318.574L212.751 265.541Z" fill="white"/>
                <path d="M212.751 371.607L265.785 424.64L212.751 477.673L159.718 424.64L212.751 371.607Z" fill="white"/>
                <path d="M212.751 477.673L265.784 530.706L212.751 583.739L159.718 530.706L212.751 477.673Z" fill="white"/>
                <path d="M212.751 583.739L265.784 636.772L212.751 689.805L159.718 636.772L212.751 583.739Z" fill="white"/>
                <path d="M159.718 106.442L212.751 159.475L159.718 212.508L106.685 159.475L159.718 106.442Z" fill="white"/>
                <path d="M159.718 212.508L212.751 265.541L159.718 318.574L106.685 265.541L159.718 212.508Z" fill="white"/>
                <path d="M159.718 318.574L212.751 371.607L159.718 424.64L106.685 371.607L159.718 318.574Z" fill="white"/>
                <path d="M159.718 424.64L212.751 477.673L159.718 530.706L106.685 477.673L159.718 424.64Z" fill="white"/>
                <path d="M159.718 530.706L212.751 583.739L159.718 636.772L106.685 583.739L159.718 530.706Z" fill="white"/>
                <path d="M159.717 0.375951L212.751 53.4091L159.718 106.442L106.684 53.4089L159.717 0.375951Z" fill="white"/>
                <path d="M159.718 636.772L212.751 689.805L159.718 742.838L106.685 689.805L159.718 636.772Z" fill="white"/>
                <path d="M212.751 689.805L265.785 742.838L212.751 795.871L159.718 742.838L212.751 689.805Z" fill="white"/>
                <path d="M159.718 742.838L212.751 795.871L159.718 848.904L106.685 795.871L159.718 742.838Z" fill="white"/>
                <path d="M212.751 795.871L265.785 848.904L212.751 901.937L159.718 848.904L212.751 795.871Z" fill="white"/>
                <path d="M159.718 848.904L212.751 901.937L159.718 954.97L106.685 901.937L159.718 848.904Z" fill="white"/>
                <path d="M212.751 901.937L265.784 954.97L212.751 1008L159.718 954.97L212.751 901.937Z" fill="white"/>
                <path d="M159.718 954.97L212.751 1008L159.718 1061.04L106.685 1008L159.718 954.97Z" fill="white"/>
                <path d="M212.751 1008L265.784 1061.04L212.751 1114.07L159.718 1061.04L212.751 1008Z" fill="white"/>
                <path d="M159.718 1061.04L212.751 1114.07L159.718 1167.1L106.685 1114.07L159.718 1061.04Z" fill="white"/>
                <path d="M212.751 1114.07L265.785 1167.1L212.751 1220.14L159.718 1167.1L212.751 1114.07Z" fill="white"/>
                <path d="M159.718 1167.1L212.751 1220.14L159.718 1273.17L106.685 1220.14L159.718 1167.1Z" fill="white"/>
                <path d="M212.751 1220.14L265.785 1273.17L212.751 1326.2L159.718 1273.17L212.751 1220.14Z" fill="white"/>
                <path d="M159.718 1273.17L212.751 1326.2L159.718 1379.24L106.685 1326.2L159.718 1273.17Z" fill="white"/>
                <path d="M212.751 1326.2L265.785 1379.24L212.751 1432.27L159.718 1379.24L212.751 1326.2Z" fill="white"/>
                <path d="M159.718 1379.24L212.751 1432.27L159.718 1485.3L106.685 1432.27L159.718 1379.24Z" fill="white"/>
                <path d="M212.751 1432.27L265.784 1485.3L212.751 1538.33L159.718 1485.3L212.751 1432.27Z" fill="white"/>
                <path d="M159.718 1485.3L212.751 1538.33L159.718 1591.37L106.685 1538.33L159.718 1485.3Z" fill="white"/>
                <path d="M106.684 53.4089L159.718 106.442L106.685 159.475L53.652 106.442L106.684 53.4089Z" fill="white"/>
                <path d="M106.685 159.475L159.718 212.508L106.685 265.541L53.652 212.508L106.685 159.475Z" fill="white"/>
                <path d="M106.685 265.541L159.718 318.574L106.685 371.607L53.6521 318.574L106.685 265.541Z" fill="white"/>
                <path d="M106.685 371.607L159.718 424.64L106.685 477.673L53.652 424.64L106.685 371.607Z" fill="white"/>
                <path d="M106.685 477.673L159.718 530.706L106.685 583.739L53.652 530.706L106.685 477.673Z" fill="white"/>
                <path d="M106.685 583.739L159.718 636.772L106.685 689.805L53.652 636.772L106.685 583.739Z" fill="white"/>
                <path d="M53.652 106.442L106.685 159.475L53.652 212.508L0.618695 159.475L53.652 106.442Z" fill="white"/>
                <path d="M53.652 212.508L106.685 265.541L53.6521 318.574L0.618738 265.541L53.652 212.508Z" fill="white"/>
                <path d="M53.6521 318.574L106.685 371.607L53.652 424.64L0.618846 371.607L53.6521 318.574Z" fill="white"/>
                <path d="M53.652 424.64L106.685 477.673L53.652 530.706L0.618792 477.673L53.652 424.64Z" fill="white"/>
                <path d="M53.652 530.706L106.685 583.739L53.652 636.772L0.61877 583.739L53.652 530.706Z" fill="white"/>
                <path d="M53.6514 0.375904L106.684 53.4089L53.652 106.442L0.618166 53.4089L53.6514 0.375904Z" fill="white"/>
                <path d="M53.652 636.772L106.685 689.805L53.6521 742.838L0.618835 689.805L53.652 636.772Z" fill="white"/>
                <path d="M106.685 689.805L159.718 742.838L106.685 795.871L53.6521 742.838L106.685 689.805Z" fill="white"/>
                <path d="M53.6521 742.838L106.685 795.871L53.6521 848.904L0.6189 795.871L53.6521 742.838Z" fill="white"/>
                <path d="M106.685 795.871L159.718 848.904L106.685 901.937L53.6521 848.904L106.685 795.871Z" fill="white"/>
                <path d="M53.6521 848.904L106.685 901.937L53.6521 954.97L0.618857 901.937L53.6521 848.904Z" fill="white"/>
                <path d="M106.685 901.937L159.718 954.97L106.685 1008L53.6521 954.97L106.685 901.937Z" fill="white"/>
                <path d="M53.6521 954.97L106.685 1008L53.652 1061.04L0.618813 1008L53.6521 954.97Z" fill="white"/>
                <path d="M106.685 1008L159.718 1061.04L106.685 1114.07L53.652 1061.04L106.685 1008Z" fill="white"/>
                <path d="M53.652 1061.04L106.685 1114.07L53.652 1167.1L0.618684 1114.07L53.652 1061.04Z" fill="white"/>
                <path d="M106.685 1114.07L159.718 1167.1L106.685 1220.14L53.652 1167.1L106.685 1114.07Z" fill="white"/>
                <path d="M53.652 1167.1L106.685 1220.14L53.6519 1273.17L0.618727 1220.14L53.652 1167.1Z" fill="white"/>
                <path d="M106.685 1220.14L159.718 1273.17L106.685 1326.2L53.6519 1273.17L106.685 1220.14Z" fill="white"/>
                <path d="M53.6519 1273.17L106.685 1326.2L53.6518 1379.23L0.618684 1326.2L53.6519 1273.17Z" fill="white"/>
                <path d="M106.685 1326.2L159.718 1379.24L106.685 1432.27L53.6518 1379.23L106.685 1326.2Z" fill="white"/>
                <path d="M53.6518 1379.23L106.685 1432.27L53.6517 1485.3L0.618425 1432.27L53.6518 1379.23Z" fill="white"/>
                <path d="M106.685 1432.27L159.718 1485.3L106.685 1538.33L53.6517 1485.3L106.685 1432.27Z" fill="white"/>
                <path d="M53.6517 1485.3L106.685 1538.33L53.6515 1591.37L0.618425 1538.33L53.6517 1485.3Z" fill="white"/>
            </svg>


                <img src="/assets/images/home/hero.webp" alt={t('HOMEPAGE').ALT_HERO_IMAGE}/>
            </StyledHeroBackground>
            <StyledHeroText ref={textRef}>
                <h2>{t('HOMEPAGE').HERO_TITLE}</h2>
                <h3>{t('HOMEPAGE').HERO_TITLE_ITALIC}</h3>
                <h2>{t('HOMEPAGE').HERO_TITLE_NORMAL}</h2>
            </StyledHeroText>
        </StyledHero>
    );
}

export { Hero };