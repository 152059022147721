import { useContext, useEffect, useRef, useState } from "react";
import { MainContext } from "../../Context";
import useOnScreen from "../../Hooks/useOnScreen";
import useWindowSize from "../../Hooks/useWindowSize";
import { ServiceContainer, ServiceTitle, ServiceDescription, SubServicesList, SubServicesListItem, SubServiceItemsContainer, MobileSubservicesContainer, SelectInput } from "./ServiceSection.styles";
import { SubserviceItem } from "./SubserviceItem";

const ConditionalWrap = ({ condition, wrap, children }) => (
    condition ? wrap(children) : children
);

const ServiceSection = ({ title, titleSpan, description, subservices, carteMenu, id, onChangeSelectionSubservice, selectedSubservices, serviceId }) => {

    const {
        selectedSubserviceGlobal,
        changeSelectedSubservice,
        onChangeService,
        serviceSelected
    } = useContext(MainContext);

    const windowSize = useWindowSize();

    const [isMobile, setIsMobile] = useState(windowSize.width <= 768);

    const titleRef = useRef();

    const titleIsVisible = useOnScreen(titleRef);

    const [selectedSubservice, setSelectedSubservice] = useState(subservices[(subservices.find(obj => obj.TITLE === selectedSubserviceGlobal)) ? subservices.findIndex(obj => obj.TITLE === selectedSubserviceGlobal) : 0]);
    const [lastSelectedSubservice, setLastSelectedSubservice] = useState(subservices[(subservices.findIndex(obj => obj.TITLE === selectedSubservice.TITLE) < subservices.length - 1) ? subservices.length - 1 : 0]);

    useEffect(() => {
        if (windowSize.width <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowSize]);

    useEffect(() => {
        if(subservices.find(obj => obj.TITLE === selectedSubserviceGlobal)) {
            changeSelectedSubservice('');
        }

        if(serviceSelected !== '') {
            window.scrollTo(0, document.querySelector('#'+serviceSelected).offsetTop - 80);
            onChangeService('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [currentServiceSelected, setCurrentServiceSelected] = useState('');

    const changeSelection = (e, subservice) => {
        e.stopPropagation();
        onChangeSelectionSubservice(selectedSubservices.findIndex((obj) => serviceId === obj.service && subservices.findIndex((obj) => obj === subservice) === obj.subservice));
    }

    const changeSubservice = (listItem, newSelectedSubservice) => {
        if(selectedSubservice !== newSelectedSubservice) {
            setCurrentServiceSelected(listItem);
            setLastSelectedSubservice(selectedSubservice)
            setSelectedSubservice(newSelectedSubservice);
        }
    }


    const [subserviceContainerHeight, setSubserviceContainerHeight] = useState(0);

    return(
        <ServiceContainer id={title.replaceAll(' ', '')+titleSpan.replaceAll(' ', '')}>
            <ServiceTitle  ref={titleRef} visibility={titleIsVisible ? 1 : 0}>{title} <span>{titleSpan}</span></ServiceTitle>
            <ServiceDescription>{description}</ServiceDescription>
            <ConditionalWrap
                condition={isMobile}
                wrap={wrappedChildren => (<MobileSubservicesContainer>{wrappedChildren}</MobileSubservicesContainer>)}>
                <SubServicesList carteMenu={carteMenu ? 1 : 0}>
                    {subservices.map(subservice => (
                        <SubServicesListItem key={subservice.TITLE} onClick={(e) => {changeSubservice(e.target, subservice);}} selected={(subservice === selectedSubservice) ? 1 : 0}><SelectInput onClick={(event) => changeSelection(event, subservice)} checked={selectedSubservices[selectedSubservices.findIndex((obj) => serviceId === obj.service && subservices.findIndex((obj) => obj === subservice) === obj.subservice)].selected}><input type='checkbox' defaultChecked={selectedSubservices[selectedSubservices.findIndex((obj) => serviceId === obj.service && subservices.findIndex((obj) => obj === subservice) === obj.subservice)].selected}/></SelectInput>{subservice.TITLE}</SubServicesListItem>
                    ))}
                </SubServicesList>
                <SubServiceItemsContainer containerHeight={subserviceContainerHeight}>
                    {subservices.map(subservice => (
                        <SubserviceItem
                            key={subservice.TITLE}
                            title={subservice.TITLE}
                            description={subservice.TEXT}
                            imageURL={subservice.IMAGE_URL}
                            imageAlt={subservice.IMAGE_ALT}
                            lastSubservice={(subservice===lastSelectedSubservice) ? true : false}
                            selectedSubservice={(subservice===selectedSubservice) ? true : false}
                            setHeight={setSubserviceContainerHeight}
                            currentServiceSelected={currentServiceSelected}
                        />
                    ))}
                </SubServiceItemsContainer>
            </ConditionalWrap>
        </ServiceContainer>
    );
}

export {ServiceSection};