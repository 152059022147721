import { Fragment } from "react";
import { Hero } from "./Hero";
import { AboutUsArticle } from "./AboutUsArticle";
import { IntroSection } from "./IntroSection";
import { useContext, useEffect } from "react";
import { MainContext } from "../Context";
import { useTranslation } from "react-i18next";

const AboutUs = () => {

    const {
        onChangeSection,
        language
    } = useContext(MainContext);

    const { t } = useTranslation();

    useEffect(() => {
        onChangeSection(t('PAGES_NAME').ABOUT_US);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const articles = t('ABOUT_US_PAGE').ARTICLES;

    return(
        <Fragment>
            <Hero />
            <IntroSection />
            <section>
                {articles.map(article => (
                    <AboutUsArticle 
                        key={article.TITLE}
                        imageURL={article.IMAGE}
                        altText={article.IMAGE_DESCRIPTION} 
                        title={article.TITLE}
                        text={article.TEXT}
                    />
                ))}
            </section>
        </Fragment>
    );
}

export { AboutUs };