import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Link } from "react-router-dom";
import { StyledNavbar, StyledLinkList, StyledListItem } from './Navbar.styles'
import { MainContext } from '../../Context';


const Navbar = ({ MenuOpened, opened }) => {

    const {
        language,
        section
    } = useContext(MainContext);    

    const { t } = useTranslation();

    const onClickLink = () => {
        MenuOpened();
    }

    return (
        <StyledNavbar opened={opened}>
            <StyledLinkList>
                <StyledListItem onClick={MenuOpened} selected={section === t('PAGES_NAME').HOME}><Link to={'/' + language + '/'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').HOME}</Link></StyledListItem>
                <StyledListItem onClick={MenuOpened} selected={section === t('PAGES_NAME').ABOUT_US}><Link to={'/' + language + '/about-us'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').ABOUT_US}</Link></StyledListItem>
                <StyledListItem onClick={MenuOpened} selected={section === t('PAGES_NAME').SERVICES}><Link to={'/' + language + '/services'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').SERVICES}</Link></StyledListItem>
                <StyledListItem onClick={MenuOpened} selected={section === t('PAGES_NAME').CONTACT}><Link to={'/' + language + '/contact'} onClick={() => window.scrollTo(0, 0)}>{t('PAGES_NAME').CONTACT}</Link></StyledListItem>
            </StyledLinkList>
        </StyledNavbar>
    );
}
export { Navbar }