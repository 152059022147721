import styled, { css } from "styled-components/macro";

export const ServiceContainer = styled.section`
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    grid-gap: 2rem;
    grid-template-rows: min-content min-content;
    margin: 3rem 0;

    @media only screen and (min-width: 769px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto auto minmax(1px, 80vh);
        width: 95%;
        margin: 3rem auto;
    }

    @media only screen and (min-width: 1024px) {
        width: 90%;
    }
    @media only screen and (min-width: 1200px) {
        width: 85%;
    }
`;

export const ServiceTitle = styled.h2`
    color: #707070;
    margin: 0;
    width: 80%;
    box-sizing: border-box;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;
    text-align: center;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px'};
        `;
    }}

    @media only screen and (min-width: 769px) {
        grid-column: span 3;
    }
`;

export const ServiceDescription = styled.p`
    width: 80%;
    margin: 0;
    color: #707070;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    text-align: center;
    white-space: pre-line;

    @media only screen and (min-width: 769px) {
        grid-column: span 3;
    }
`;

export const SubServicesList = styled.ul`
    list-style: none;
    padding: 0;
    height: fit-content;
    overflow-y: scroll;
    width: 80%;
    position: relative;
    grid-row-start: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-right: 0.5rem;
    margin: 0;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }

    @media only screen and (min-width: 769px) {
        grid-row-start: initial;
        height: 100%;
        text-align: left;
    }

    ${({ carteMenu }) => {
        return css`
            & li > span {
                display: ${carteMenu ? 'initial' : 'none'};
            }
        `;
    }}
`;

export const SubServiceItemsContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: height .5s ease-in-out;
    align-self: start;
    
    @media only screen and (max-width: 768px) {
        position: sticky;
        top: 5rem;
        left: 0;
        ${({ containerHeight }) => {
        return css`
            height: ${containerHeight + 'px'};
        `;
        }}
    }

    @media only screen and (min-width: 769px) {
        max-height: 80vh;
        margin: 0;
        grid-column: span 2;
        padding-right: 1rem;
    }
`;

export const SubServicesListItem = styled.li`
    list-style: none;
    width: 100%;
    margin: .5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ selected }) => {
        return css`
            font-weight: ${selected ? 'bold' : '300'};
            &:hover {
                cursor: ${selected ? 'initial' : 'pointer'};
                color: ${selected ? 'initial' : '#707070'};
                text-decoration: ${selected ? 'none' : 'underline'};
            }
        `;
    }}

    @media only screen and (min-width: 769px) {
        justify-content: flex-start;
    }
`;

export const MobileSubservicesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: fit-content;
`;

export const SelectInput = styled.span`
    display: block;
    width: 1rem;
    height: 1rem;
    position: relative; 
    margin: 0 .5rem;
    border-radius: 20%;
    cursor: pointer;
    background-size: cover;

    ${({ checked }) => {
    return css`
        background-color: #fcfff4;
        background-image: ${(checked) ? "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg id='Rectangle_85' data-name='Rectangle 85' fill='%23354860' stroke='%23354860' stroke-width='1px'%3E%3Crect width='30' height='30' rx='8' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='7.5' fill='none'/%3E%3C/g%3E%3Cpath id='Path_48' data-name='Path 48' d='M704,354.774c.068-.057.139-.111.2-.172.658-.616,1.316-1.232,1.991-1.865l3.316,3.538c.1-.091.195-.176.288-.263l7.512-6.969c.014-.013.027-.029.04-.044h.03a1.426,1.426,0,0,0,.1.129q.579.63,1.16,1.258c.251.271.506.539.759.808v.03a.8.8,0,0,0-.071.053q-4.925,4.564-9.849,9.129a1.16,1.16,0,0,0-.094.111h-.03c-.018-.023-.034-.048-.054-.069q-1.939-2.07-3.878-4.14-.708-.755-1.42-1.5Z' transform='translate(-696.698 -339.259)' fill='%23fff' stroke='%23354860' stroke-width='1'/%3E%3C/svg%3E%0A\")" : "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cg id='Rectangle_84' data-name='Rectangle 84' fill='none'  stroke='%23354860' stroke-width='1px'%3E%3Crect width='30' height='30' rx='8' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='29' height='29' rx='7.5' fill='none'/%3E%3C/g%3E%3Cg id='Group_98' data-name='Group 98' transform='translate(-701 -1716)'%3E%3Cpath id='Path_46' data-name='Path 46' d='M15374.846,2979.024v15.4' transform='translate(-14659 -1256)' fill='none' stroke='%23354860' stroke-width='3'/%3E%3Cpath id='Path_47' data-name='Path 47' d='M15374.846,2979.024v15.4' transform='translate(3702.568 -13644.123) rotate(90)' fill='none' stroke='%23354860' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E \")"};
    `;
    }}

    & input {
        display: none
    }
`;