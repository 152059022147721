import { useTranslation } from "react-i18next";
import { StyledHero, StyledHeroBackground } from "./Hero.styles";

const Hero = () => {

    const { t } = useTranslation();

    return (
        <StyledHero>
            <StyledHeroBackground>
                <img src="/assets/images/services/hero.webp" alt={t('SERVICES_PAGE').ALT_IMAGE_HERO}/>
            </StyledHeroBackground>
        </StyledHero>
    );
}

export { Hero };