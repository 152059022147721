import styled from "styled-components";

export const WhatsAppButtonContainer = styled.a`
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #7BA3A0;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    & svg {
        height: inherit;
        width: inherit;
    }
`;