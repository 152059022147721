import { LangSelector } from "./LangSelector";
import { Navbar } from "./Navbar";
import { HeaderLogo } from "./HeaderLogo";
import { StyledHeader, ShadowBackground } from "./Header.styles";
import { MenuTrigger } from "./MenuTrigger";
import { Fragment, useState, useEffect } from "react";

const Header = () => {

    const [isGreaterThan1200px, setIsGreaterThan1200px] = useState(false);
    const [show, setShow] = useState(false);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        function handleResize() {
            setShow(false);
            setOpened(false);

            if (window.innerWidth > 1200) {
                setIsGreaterThan1200px(true);
            } else {
                setIsGreaterThan1200px(false);
            }
        }

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const MenuOpened = () => {
        if(!opened) {
            setShow(true);
        } else {
            setTimeout(() => {
                setShow(false);
            }, 500);
        }
        setOpened(!opened);
    }

    return (
        <Fragment>
            <StyledHeader>
                <MenuTrigger MenuOpened={MenuOpened} opened={opened}/>
                {(isGreaterThan1200px) ?
                <Fragment>
                    <HeaderLogo/>
                    <Navbar/>
                    <LangSelector/>
                </Fragment> : 
                <Fragment>
                        <Navbar MenuOpened={MenuOpened} opened={opened}/>
                        <HeaderLogo/>
                        <LangSelector/>
                </Fragment>
                }
            </StyledHeader>
            <ShadowBackground opened={opened} show={show} />
        </Fragment>
    );

}

export { Header };