import React from 'react';
import { StyledLangSelector } from './LangSelector.styles';
import { MainContext } from '../../Context';
import { useTranslation } from 'react-i18next';
 
const LangSelector = () => {
  const {
    changeLanguage
  } = React.useContext(MainContext);

  const { i18n } = useTranslation();

  const onChangeValue = (event) => {
    changeLanguage(event.target.value);
  }
 
  return (
    <StyledLangSelector onChange={onChangeValue} defaultValue={i18n.language}>
      <option value="en" name="language" >en</option>
      <option value="es" name="language" >es</option>
    </StyledLangSelector>
  )
}
 
export { LangSelector };