import styled from "styled-components";

export const FooterContainer =  styled.footer`
    background-color: #A4A69B;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 0;
`;

export const StyledTitle = styled.h2`
    font-size: 2rem;
    font-weight: 200;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-top: 0;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    @media only screen and (min-width: 600px) {
        font-size: 2.5rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 3rem;
    }
`;

export const StyledSocialNav = styled.nav`
    display: flex;

    & a {
        height: 2rem;
    }

    & a:first-of-type {
        margin-right: 1rem;
    }

    & svg {
        height: inherit;
    }
`;

export const StyledCopyRightText = styled.p`
    font-size: .8rem;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;
`;