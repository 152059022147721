import styled, { css } from "styled-components/macro";

export const ArticleContainer = styled.article`
    display: grid;
    width: 100vw;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    margin-top: 3rem;

    &::after {
        position: absolute;
        content: '';
        height: 2px;
        margin: 0;
        right: 0;
        top: 0;
        background: #707070;
        opacity: 1;
        transition: .5s;
        width: 80%;
        transition: all .5s .3s ease-in-out;
    }

    &:nth-of-type(2n+1){
        & h2, & p{
            text-align: right;
        }

        &::after {
            left: 0;
        }
    }

    @media only screen and (min-width: 1024px) {
        width: 90vw;
        margin: 3rem 5vw;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        &:nth-of-type(2n+1) figure{
            grid-column-start: 1;
            grid-row-start: 1;
        }

        &:nth-of-type(2n+1)::after{
            translate: 1rem 0;
            left: 0;
        }
        
        &::after {
            position: absolute;
            content: '';
            height: 1px;
            margin: 0;
            right: 0;
            top: -1rem;
            background: #707070;
            opacity: 1;
            transition: .5s;
            box-sizing: border-box;
            translate: -1rem 0;
            transition: all .5s .3s ease-in-out;

            ${({ visibility }) => {
            return css`
                width: ${visibility ? 'calc(50% - 2.5rem)' : '0%' };
            `;
            }}
        }
    }
`;

export const ArticleContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90%;
    margin: 2rem 5%;
    box-sizing: border-box;

    @media only screen and (min-width: 425px) {
        width: 80%;
        margin: 2rem 10%;
    }

    @media only screen and (min-width: 769px) {
        width: 100%;
        margin: 0;
        padding: 1rem;
    }
`;

export const ArticleTitle = styled.h2`
    color: #707070;
    width: 100%;
    box-sizing: border-box;
    margin: 1rem 0;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    transition: all .5s ease-in-out;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}
`;

export const ArticleText = styled.p`
    color: #707070;
    width: 100%;
    margin: 0;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
`;

export const ArticleImage = styled.figure`
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    transition: all .5s ease-in-out;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-sizing: border-box;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-delay: .5s;

        ${({ visibility }) => {
        return css`
            animation-name: ${visibility ? 'zoom-in' : 'none' };
        `;
        }}
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}

    @media only screen and (min-width: 1024px) {
        
    }

    @keyframes zoom-in {
        to {
            scale: 1.5;
        }
    }
`;