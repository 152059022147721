import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

export const StyledAboutUsSectionContainer = styled.section`
    display: grid;
    width: 100vw;
    overflow: hidden;
    flex-wrap: wrap;
    position: relative;
    justify-items: center;
    align-items: center;
    grid-gap: 1rem;
    margin-top: 3rem;
    
    @media only screen and (min-width: 769px) {
        padding: 5rem 0 1rem;
        grid-template-columns: 40% 60%;
    }
`;

export const StyledMainPhrase = styled.h2`
    color: #707070;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
    margin: 0;
    position: relative;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;

    & span {
        font-family: 'Minion Pro', sans-serif;
        font-weight: normal;
        font-style: italic;
    }

`;

export const StyledImageContainer = styled.figure`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    position: relative;
    transition: all .5s ease-in-out;

    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
        animation-delay: .5s;

        ${({ visibility }) => {
        return css`
            animation-name: ${visibility ? 'zoom-in' : 'none' };
        `;
        }}
    }

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}

    @keyframes zoom-in {
        to {
            scale: 1.5;
        }
    }
`;

export const StyledAboutUsLink = styled(Link)`
    width: fit-content;
    text-align: center;
    text-decoration: none;
    color: #707070;
    position: relative;
    text-transform: uppercase;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: bold;
    font-style: normal;
    margin: 0;
    margin-bottom: 2rem;

    &::after {
        position: absolute;
        content: '';
        height: 2px;
        margin: 0;
        left: 0;
        bottom: -2px;
        background: #707070;
        opacity: .8;
        transition: .5s;
        width: 100%;
        transform-origin: center;
    }

    @media only screen and (min-width: 769px) {
        margin-bottom: 0;
    
        &::after {
            width: 100%;
            left: 0%;
        }

        &:hover::after {
            width: 0%;
            left: 50%;
        }
    }
`;

export const StyledTextDescription = styled.p`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;
    font-family: 'Kross Neue Grotesk', sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #707070;

    @media only screen and (min-width: 769px) {
        grid-column-start: 1;
        grid-column-end: 3;
        width: 60%;
        justify-self: flex-end;
        margin: 0;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 1rem;
    padding: 0 10%;
    transition: all .5s ease-in-out;

    ${({ visibility }) => {
        return css`
            opacity: ${visibility ? '1' : '0'};
            translate: 0 ${visibility ? '0' : '-40px' };
        `;
    }}

    @media only screen and (min-width: 769px) {
        padding: 10% 0 10% 10%;
    }

    @media only screen and (min-width: 1024px) {
        padding-left: 20%;
    }
`;